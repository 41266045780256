import { deleteRequest, get, post, put } from "../helpers/apihelpers";

export const CreateUserCallService=async(data)=>{
    try {
        const result = await post(`api/user_calls/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateUserCallService=async(data,id)=>{
    try {
        const result = await put(`api/user_calls/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetUserCallService=async(page)=>{
    try {
        const result = await get(`api/user_calls/get?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetUserCallDetailService=async(id,page)=>{
    try {
        const result = await get(`api/user_calls/get/${id}?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const DeleteUserCallService=async(id)=>{
    try {
        const result = await deleteRequest(`api/user_calls/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterUserCallService=async({search_text,from_date,to_date,page})=>{
    try {
        const result = await get(`api/user_calls/filter?search=${search_text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}