import { IconButton, Tooltip } from '@mui/material'
import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
// import { CreateLeadsListBasedonIdService, DeleteLeadService, GetLeadNoteService, GetLeadReminderService, GetLeadsService, SearchLeadService } from '../../services/LeadServices';
import moment from 'moment';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { DatePicker } from 'antd';
import {RiBillLine} from 'react-icons/ri';
// import DailyTask from './dailyTask/DailyTask';
import {AiOutlineFilter,AiOutlinePlus,AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai';
import axios from 'axios';
import fileDownload from "js-file-download";
import { toast } from 'react-hot-toast';
// import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import CallsMenu from './CallsMenu';
import { DeleteUserCallService, FilterUserCallService, GetUserCallService } from '../../services/UserCallsServices';

function CallsList() {

  const location = useLocation();
  const user = useSelector(state=>state.Auth)
  const navigate = useNavigate();

  let path = location?.pathname?.split('/')[2]

  const [data,setdata]  = useState([])
  const [type,settype] = useState('')
  const [page,setpage] = useState(1)
  const [filter_drawer,setfilter_drawer] = useState(false)


  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})

 
  
  useEffect(()=>{
    setpage(1)
  },[location.pathname])


  useEffect(()=>{
    if(search.activate){
      applyfilterfunction(page)
    }else{
      getdata(page)
    }
  },[page])


  
  async function getdata(page){
      setfilter_drawer(false)
      const response =  await GetUserCallService(page);
      setdata(response.data)
  }

  async function applyfilterfunction(page){
    setsearch({...search,activate:true})
    let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    const response = await FilterUserCallService({search_text:search.text,from_date:(search?.from_date !== ''  && search?.from_date !== null ) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
    setdata(response.data)
  }

  async function resetfunc(){
    setsearch({text:'',from_date:'',to_date:'',activate:false})
    getdata(1)
  }

  

  const deleteData = async(v)=>{
    const response = await DeleteUserCallService(v)
    if(response?.status === 200){
      toast.success(response?.data?.datas)
    }
  }



  

  


  return (
    <div className='h-screen w-full  flex max-h-screen '>
  
     <CallsMenu />
      <div className='px-3 md:px-4  pt-4 w-[100%]'>
      <div  className={`${data?.datas?.length === 0 ? 'w-[100%]' : 'w-[100%] md:w-[96%]'}`}>
        <div  className='w-[100%] '>
          <div className='block   md:flex items-center justify-between border-b pb-2'>
            <div className='flex justify-between items-center'>
              <h6 className='font-[500] text-[13px] '>Total Calls {type} ({data?.pagination?.total})</h6>
              <div className='block md:hidden  items-center'>
              <div className='flex items-center text-[12px] -mr-2 md:mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>
              </div>
              </div>

              <div className='flex items-center md:justify-between mt-2 md:mt-0'>
              <div className='hidden md:block  items-center'>
              <div className='flex items-center text-[12px] -mr-2 md:mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>
              </div>
              <DatePicker size='small' style={{fontSize:'11px',minWidth:'90px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

              <DatePicker size='small' style={{fontSize:'11px',minWidth:'90px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

              <div className='mr-2'>
              <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className=' text-[12px] border py-[6.5px] focus:ring-0 focus:outline-0 w-[70px] md:w-28 px-2 rounded-md border-slate-300' />
              </div>      

              <div className='hidden md:block'>
              <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
              </div>
              <AiOutlineFilter onClick={()=>applyfilterfunction(1)}  size={26} className='border-slate-300 bg-[#f6f8fa] rounded-[3px] px-1 p-1 text-[#000] block md:hidden' />
              <div className='ml-2 hidden md:block'>
              <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('/calls/list/create')}/> 
              </div>
              <AiOutlinePlus onClick={()=>navigate('/calls/list/create')} size={26}  className='bg-amber-400 rounded-[3px] px-1 ml-2 p-1 text-black block md:hidden' />
              </div>
        </div>
        
        {data?.datas?.length === 0 &&
          <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[400] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
        </div>
        }

        {data?.datas?.length > 0 &&
        <div className='max-h-[85vh] w-auto mt-2 overflow-y-scroll'>

        <div className='grid grid-cols-2 md:grid-cols-5 gap-1'>
            {data?.datas?.map((d)=>(
                <div key={d?._id} className='border p-2 mr-1 relative'>
                  <span className='flex absolute right-1 top-1 cursor-pointer' ><AiOutlineDelete size={13} className='mr-1' onClick={()=>deleteData(d?._id)} /><AiOutlineEdit size={13} onClick={()=>navigate('/calls/list/edit',{state:d})} /></span>
                    <h6 className='text-[12px] font-[700] capitalize'>{d?.name}</h6>
                    <h6 className='text-[10px] mt-1 font-[400] '>Mobile :  {d?.phone}</h6>
                    <h6 className='text-[11px] font-[300] p-1 bg-slate-100 mt-1'>Comment : {d?.comments}</h6>
                    <h6 className='text-[11px] font-[300] p-1 mt-1'>Created By : <span className='font-[600]'>{d?.created_by?.name}</span> </h6>
                    
                    <h6 className='text-[9px] my-1'>Created At : {moment(d?.createdAt)?.format('LLL')}</h6>
                </div>    
            ))}

        </div>        
       
        </div>}
        </div>

        
      


      </div>
      </div>
    </div>
  )
}

export default CallsList