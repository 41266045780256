import React,{useState,useEffect} from 'react';
import { GetOnboardListService } from '../../services/OnboardServices';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {AiOutlineEdit} from 'react-icons/ai';
import SettingsMenu from '../staticscreens/SettingsMenu';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import {MdPassword} from 'react-icons/md';
import { Modal } from 'antd';
import { TextInput } from '../../components/input';
import {  GetSearchService, UpdateUserPasswordService } from '../../services/AuthServices';
import { toast } from 'react-hot-toast';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material'
import {BsArrowRepeat} from 'react-icons/bs';

function OnboardList() {
  
  const [page,setpage] = useState(1)
  const [data,setdata] = useState([])  
  const [selectedData,setselectedData] = useState({})
  const [modal,setmodal] = useState(false)
  const [filterApplied,setfilterApplied] = useState(false)
  const [search,setsearch] = useState('')
  const navigate = useNavigate() 

  const [password,setpassword] = useState('')
  const [error,seterror] = useState('')


  useEffect(()=>{
    if(filterApplied){
      searchfunction()
    }else{
        getOnboardList()
    }
  },[page])  

  
  async function getOnboardList(){
      const response = await GetOnboardListService(page);
      setdata(response.data)
  }

  async function updatePassword(){
    if(password.length < 8){
       seterror('Password Shoild be atleast 8 characters')
    }else{
      let send_Data = {
        'user_id':selectedData?._id,
        'password':password
      }
      const response = await UpdateUserPasswordService(send_Data)
      if(response.status === 200){
        setpassword('')
        seterror('')
        setmodal(false)
        toast.success(response?.data?.message)
      }
    }
  }

  async function searchfunction(){
    if(search.length < 3){

    }else{
      const response = await GetSearchService(search,page)
      setdata(response.data)
      setfilterApplied(true)
    }
    
  }

  function setpasswordfunc(e){
    setpassword(e.target.value)
    seterror('')
  }

  async function resetfunc(){
    setpage(1)
    setfilterApplied(false)
    getOnboardList()
    setsearch('')
  }

  return (
    <div>     

      <Modal 
       open={modal}
       closable={false}
       footer={false}
       width={300}>
        <div className='relative'>
        <h6 className="font-bold text-[15px]  mb-2 w-full">Update Password</h6>
          <h6 className='bg-slate-100 text-[12px] p-1.5 font-[400]'>Are you sure to update the password for the employee who is having a name of <span className='font-[700]'>[{selectedData?.name}]</span>  who is having a employee_id of <span className='font-[700]'>[{selectedData?.employee_id}]</span> </h6>

          <TextInput error={error} name="password" value={password} handlechange={setpasswordfunc} /> 
          <div className='flex justify-end mt-3 '>
            {/* <div className='mr-1 w-full'> */}
            <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setmodal(false)} />
            {/* </div> */}
            <div  className='ml-2'>
            <ButtonFilledAutoWidth btnName={'Confirm'} onClick={updatePassword}  /> 
            </div>
          </div>
        </div>
      </Modal>
      <div className='md:flex'>
      <SettingsMenu />
      <div  className='md:w-[85%] px-4 pt-2 md:pt-5'>
       <div sx={{display:{sm:'none',xs:'none',lg:'block',md:'block'}}}>
        <div className='md:flex items-center justify-between border-b pb-2'>
        <div className='flex items-center justify-between'>
        <h6 className='font-[600] text-[14px] '>{'Onboard Request'} ({data?.pagination?.total})</h6>

        <div className='flex'>

        <div className='flex md:hidden items-center  text-[12px] mr-2'>

        <h6 className='mr-2 font-[500]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
        <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

        <div>
        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}} ><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
        <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${data?.pagination?.totalPages === page ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

        </div>
        </div>
        </div>
        </div>

        <div className='flex items-center mt-2'>
        <div className='hidden md:flex items-center  text-[12px] mr-2'>

          <h6 className='mr-2 font-[500]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
          <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

          <div>
          <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}} ><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
          <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${data?.pagination?.totalPages === page ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

          </div>
          </div>

        <div>
          
          <input id="search_text" placeholder='Search Employee' type='text' value={search}  onChange={(e)=>setsearch(e.target.value)} className='border text-sm py-[4px] focus:ring-0  focus:outline-0 text-[13px]  w-28 px-1 rounded-md border-slate-300' />
        </div>
        <div className="ml-2 flex">
        <ButtonOutlinedAutoWidth onClick={()=>{setpage(1);searchfunction()}}  btnName="Add Filter" />

        <div className="ml-2">
        <ButtonFilledAutoWidth btnName="Add New" onClick={()=>navigate('create')}   />
        </div> 
        </div> 
        </div>

       </div>

       {data?.datas?.length === 0 &&
        <div className='grid place-items-center mt-20  items-center justify-center'>
        <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
        <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
        <h6 className='font-[500] text-center w-[70%] text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any "Employee" onboarded yet click on the add option to create a new employee for your organization.</h6>
       </div>
       }
       {data?.datas?.length !== 0 &&
       <div className='max-h-[85vh] overflow-y-scroll border-t border-l border-r mt-2'>
       <div className='top-0 z-50 flex  bg-white py-[-5px]  border-slate-200 relative'>
          <h6 className='text-[13px]  min-w-[25px]  md:w-[25px]  px-2 py-1 font-[500]  text-slate-600 border-b border-r'></h6>
          <h6 className='text-[12px]  min-w-[50px]  md:w-[50px]  px-2 py-1 font-[400] border-b border-r capitalize'>Photo</h6>
          <h6 className='text-[12px]  min-w-[80px]  md:w-[80px]  px-2 py-1 font-[400] border-b border-r capitalize'>Emp Id</h6>
          <h6 className='text-[12px]  min-w-[140px] md:w-[140px] px-2 py-1 font-[400] border-b border-r capitalize'>Name</h6>
          <h6 className='text-[12px]  min-w-[100px] md:w-[100px] px-2 py-1 font-[400] border-b border-r capitalize'>Phone</h6>
          <h6 className='text-[12px]  min-w-[160px] md:w-[160px] px-2 py-1 font-[400] border-b border-r capitalize'>Email</h6>
          <h6 className='text-[12px]  min-w-[160px] md:w-[160px] px-2 py-1 font-[400] border-b border-r capitalize'>Department</h6>
          <h6 className='text-[12px]  min-w-[180px] md:w-[180px] px-2 py-1 font-[400] border-b border-r capitalize'>Roles</h6>
          <h6 className='text-[12px]  min-w-[90px]  md:w-[90px]  px-2 py-1 font-[400] border-b border-r capitalize'>Joined Date </h6>
          <h6 className='text-[12px]  min-w-[90px]  md:w-[90px]  px-2 py-1 font-[400] border-b border-r capitalize'>Created On </h6>
          <h6 className='text-[12px]  min-w-[50px]  md:w-[50px]  px-1 py-1 font-[400] border-b capitalize'>Action</h6>
       </div>
     

       {data?.datas?.map((d,i)=>(
       <div key={d?._id} className='flex  cursor-pointer z-0'  >
          <h6  onClick={()=>navigate('edit',{state:d?._id})} className='text-[11px] min-w-[25px] md:w-[25px] text-center  px-2 py-1 truncate border-b border-r '>{i+1}</h6>
          <h6  onClick={()=>navigate('edit',{state:d?._id})} className='text-[11px] min-w-[50px] flex items-center justify-center w-[50px]  py-1 truncate border-b border-r'>{(d?.profile_photo !== null && d?.profile_photo !== undefined) ? <img className=" w-5 h-5 rounded object-contain" src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.profile_photo}`} alt="Default avatar"></img> : <span className='bg-gray-100 w-5 h-5 flex items-center justify-center text-[10px] rounded uppercase font-[700]'>{d?.name?.slice(0,2)}</span>}</h6>
          <h6  onClick={()=>navigate('edit',{state:d?._id})} className='text-[11px] min-w-[80px] w-[80px] font-[600] px-2 py-1 truncate border-b border-r '>{d?.employee_id}</h6>
          <h6  onClick={()=>navigate('edit',{state:d?._id})} className='text-[11px] min-w-[140px] w-[140px]  font-[600] px-2 py-1 truncate border-b border-r '>{d?.name}</h6>
          <h6  onClick={()=>navigate('edit',{state:d?._id})} className='text-[11px] min-w-[100px] w-[100px]  px-2 py-1 truncate border-b border-r '>{d?.phone}</h6>
          <h6  onClick={()=>navigate('edit',{state:d?._id})} className='text-[11px] min-w-[160px] w-[160px] font-[600] px-2 py-1 truncate border-b border-r '>{d?.email}</h6>
          <h6  onClick={()=>navigate('edit',{state:d?._id})} className='text-[11px] min-w-[160px] w-[160px] px-2 py-1 truncate border-b border-r '>{d?.department_id[0]?.department_name !== undefined && <span className='text-[9px] font-semibold inline-block py-1 px-2 uppercase rounded text-slate-600 bg-slate-100 uppercase last:mr-0 mr-1'>{d?.department_id[0]?.department_name}</span>}</h6>
          <h6 className='text-[11px] min-w-[180px] w-[180px] px-2 py-1 font-[500]   text-slate-600 border-b border-r capitalize truncate'>{d?.user_roles?.map((r)=>(<span key={r?.roles?._id} className="text-[9px] font-semibold inline-block py-1 px-2 uppercase rounded text-slate-600 bg-slate-100 uppercase last:mr-0 mr-1">{r?.roles?.display_name}</span>))}</h6>
          <h6  onClick={()=>navigate('edit',{state:d?._id})} className='text-[10px] min-w-[90px] w-[90px] font-[600] px-2 py-1 truncate border-b border-r '>{moment(d?.doj).format('ll')}</h6>
          <h6  onClick={()=>navigate('edit',{state:d?._id})} className='text-[10px] min-w-[90px] w-[90px]  px-2 py-1 truncate border-b border-r '>{moment(d?.createdAt).format('ll')}</h6>
          <h6  className='text-[12px] min-w-[50px] w-[50px] flex items-center border-b justify-center '>
          
            <AiOutlineEdit size={12} className="z-10 cursor-pointer" onClick={()=>navigate('edit',{state:d?._id})}/>
            <MdPassword  className='ml-2 cursor-pointer' size={12} onClick={()=>{setselectedData(d);setmodal(true)}} />
          </h6>
       </div>))}
       </div>}
       </div>

      



      </div>
      </div> 
    </div>
  )
}

export default OnboardList