import React, { useEffect, useState } from 'react';
import {RiUser6Line,RiSettingsLine,RiNotification3Line,RiBrush4Line} from 'react-icons/ri';
import {IoAccessibilityOutline} from 'react-icons/io5';
import {BsShieldLock} from 'react-icons/bs'
import { useLocation,useNavigate } from 'react-router-dom';
// import {DatePicker} from 'antd';



function ProfileMenu() {

  const {pathname} = useLocation();
  const navigate = useNavigate();

  const [step,setstep] = useState(1)

  const menu = [
    {name:'Public profile',icon:RiUser6Line,path:'/profile/basic',id:1,},
    {name:'Account',icon:RiSettingsLine,path:'/profile/account',id:2},
    {name:'Appearance',icon:RiBrush4Line,path:'/profile/appearance',id:3},
    {name:'Accessibility',icon:IoAccessibilityOutline,path:'/profile/accessibility',id:4},
    {name:'Notifications',icon:RiNotification3Line,path:'/profile/notifications',id:5}
  ]  
  
  const menu1 = [
    {name:'Password Update',icon:BsShieldLock,path:'/profile/update_password',id:1},
    {name:'Organization',icon:RiSettingsLine,path:'/profile/organiztion',id:2},
  ] 


  useEffect(()=>{
    menu?.filter((f)=>f.path === pathname)?.length > 0 ? setstep(1) : setstep(2)
  },[pathname])


  return (
    <div className='w-full mr-0 md:min-h-screen md:max-h-sceen md:border-r overflow-hidden md:min-w-48 md:max-w-48 md:w-48 px-2 pt-5'>

        <div className='flex mx-3 items-center'>
          <div onClick={()=>setstep(1)} className='relative cursor-pointer mr-2'>
            <h6 className={`mb-2  rounded-md  px-1.5 py-1.5 font-[500] w-full ${step === 1 && 'bg-[#f4f5f7]'} block md:hidden text-[12px]`}>Basic Menu</h6>
            {step === 1 && <span className='block md:hidden border-slate-800 border-2 h-6 rounded absolute -ml-1.5 top-0' ></span>}
          </div>
          <div onClick={()=>setstep(2)} className='relative cursor-pointer'>
            <h6 className={`mb-2  rounded-md  px-1.5 py-1.5 font-[500] w-full ${step === 2 && 'bg-[#f4f5f7]'}  block md:hidden text-[12px]`}>Feature Access</h6>
            {step === 2 && <span className='block md:hidden border-slate-800 border-2 h-6 rounded absolute -ml-1.5 top-0' ></span>}
          </div>
        </div> 
       
        
        {step === 1 &&
        <div className='block md:hidden mb-4 mx-1'>
        <h6 className='mb-2 font-[700] hidden md:block text-[12px]'>Menu</h6>
            <div className='flex overflow-y-scroll md:block w-auto'>
            {menu.map((m,i)=>(
                <div key={m?.path} className={`flex ${i === 0 && 'min-w-[130px]'}  pr-4 md:pr-0 items-center  my-1 hover:bg-[#f4f5f7] px-1.5 py-1.5 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[400] ml-2 text-[12px]'>{m.name}</span>
              </div>     
            ))}
            </div>
        </div>}

        {step === 2 &&
        <div className='block md:hidden border-t pt-4'>
            <h6 className='mb-2 font-[700] hidden md:block text-[12px]'>Access</h6>
            <div className='flex md:block w-auto'>

            {menu1.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 mr-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[400] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}
            </div>
        </div>}


        <div className='hidden md:block  mb-4'>
        <h6 className='mb-2 font-[700] hidden md:block text-[12px]'>Menu</h6>
            <div className='flex md:block w-auto'>
            {menu.map((m)=>(
                <div key={m?.path} className={`flex  pr-4 md:pr-0 items-center  my-1 hover:bg-[#f4f5f7] px-1.5 py-1 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[400] ml-2 text-[12px]'>{m.name}</span>
              </div>     
            ))}
            </div>
        </div>

        <div className='hidden md:block border-t pt-4'>
            <h6 className='mb-2 font-[700] hidden md:block text-[12px]'>Access</h6>
            {menu1.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1  rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[400] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}
        </div>

    </div>
  )
}

export default ProfileMenu