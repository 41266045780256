import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import { TextInput } from '../../../components/input'
import { useNavigate, useLocation } from 'react-router-dom'
import { CreateDesignationService, UpdateDesignationService } from '../../../services/DesignationService'
import SettingsMenu from '../../staticscreens/SettingsMenu'
import GoBack from '../../../components/back/GoBack'
import { ButtonFilledAutoWidth } from '../../../components/button'

function DesignationCE() {
 
  const [data,setdata] = useState({designation_name:''});  
  const [error,seterror] = useState({designation_name:''}); 
  
  const {state} = useLocation();
  const navigate = useNavigate();

  // const {id,department_name} = state;


  useEffect(()=>{
    if(state?.id !== null && state?.id !== undefined){
      setdata({...data,designation_name:state.designation_name})
    }

  },[state])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function submitform(){
    console.log("iam there na")
    if(!data.designation_name){
        seterror({...error,designation_name:'Designation Name field is required *'})
    }else{
      console.log('amma anna',state?.id)
        if(state?.id === undefined || state?.id === null){
        const response = await CreateDesignationService(data)
        if (response.status === 201) {
          setdata({...data,designation_name:''})
          seterror({...error,designation_name:''})
          toast.success('Designation Created Successfully')
        }   
        if(response.status === 422){
          seterror({...error,designation_name:response?.data?.errors?.designation_name})
        } 
      }else{
        let send_data = {...data}
        send_data["id"] = state?.id
        const response = await UpdateDesignationService(send_data,state?.id)
        if (response.status === 200) {
          setdata({...data,designation_name:''})
          seterror({...error,designation_name:''})
          navigate(-1)
          toast.success('Designation Updated Successfully')
        }   
        if(response.status === 422){
          seterror({...error,designation_name:response?.data?.errors?.designation_name})
        } 
      }
  }
  }

  

  return (
    <div className='md:flex '>

    <div className='hidden md:block'>
    <SettingsMenu />
    </div> 
 
  <div className='sm:w-full lg:w-72 px-4 pt-5' >


      <GoBack /> 
      

        <h6 className='font-bold'>{(state?.id !== null && state?.id !== undefined) ? 'Edit' : 'Add'}  Designation</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Designation</b> for your comapny employees.</h6>
     

        <TextInput 
            label={'Designation Name'}  
            variant="standard"
            name="designation_name"
            type="text"
            error={error.designation_name}
            value={data.designation_name}
            handlechange={handlechange}
            placeholder="Enter your Designation name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?.id !== null && state?.id !== undefined) ? "UPDATE DESIGNATION" : "ADD DESIGNATION"}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default DesignationCE