import { deleteRequest, get, publicPost, put } from "../helpers/apihelpers";

export const CreateDepartmentService=async(data)=>{
    try {
        const result = await publicPost(`api/department/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateDepartmentService=async(data,id)=>{
    try {
        const result = await put(`api/department/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetDepartmentService=async(department)=>{
    try {
        const result = await get(`api/department/get?department=${department}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteDepartmentService=async(id)=>{
    try {
        const result = await deleteRequest(`api/department/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetUsersByDepartment = async(id)=>{
    try {
        const result = await get(`api/user/get-users-by-departmentId/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}