import React, { useEffect } from 'react';
import {useLocation,useNavigate } from 'react-router-dom';
import {BiPhoneCall,} from 'react-icons/bi';


function AllocateMenu() {

  const {pathname} = useLocation();
  
  const navigate = useNavigate();

  const menu = [
    {name:'Site Allocated',icon:BiPhoneCall,path:'/allocate/site_list',id:1,color:''},
  ]  

  useEffect(()=>{
         if(pathname === '/allocate'){
          navigate('/allocate/site_list')
         }
  },[pathname])

 
  return (
    <div className='hidden md:block mr-0 min-h-screen max-h-sceen border-r min-w-[170px] max-w-[170px] px-2' >

        <div className='mb-4 mt-5'>
          <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Basic Option</h6>

          {menu.map((m)=>(
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[400] ml-2 text-[12px]'>{m.name}</span>
              </div>    
          ))}
          </div>


         
    </div>
  )
}

export default AllocateMenu