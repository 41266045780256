
import React,{useState,useEffect} from 'react'
import {RiCustomerService2Line} from 'react-icons/ri';
import DashboardMenu from './DashboardMenu'
// import { GetTodayLeadReminderService, getInsentiveTargetAchieved, getTargetAchieved } from '../../services/LeadServices';
import { BiLandscape } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetHomeDataService } from '../../services/HomeDataServices';

function Dashboard() {


  const navigator = useNavigate()
  const [data,setdata] = useState({})
  
  useEffect(()=>{
    getData()
  },[])

  async function getData(){
    const response = await GetHomeDataService()
    setdata(response?.data?.data)
  }





  let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

 

  return (
    <div className='h-screen min-h-screen max-h-screen overflow-hidden'>

      <div className='flex'>
        <DashboardMenu />
        <div className='w-[100%] md:w-[86%] flex h-screen min-h-screen max-h-screen overflow-y-scroll'>
          <div className='w-[100%] px-4 pt-5 '>
            <div className='grid border-l border-r grid-cols-2 md:grid-cols-6 items-center justify-evenly border-t border-b border-slate-100'>
                  <div className='border-r  border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/allocate/site_list')}>
                    <h6 className='text-[12px] text-slate-700 font-[600] flex items-center'><BiLandscape size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Land Allocated </h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.land_allocate))?.split('.')[0].slice(1)}</h6>
                  </div>
                  <div className='border-r  border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/calls/list')}>
                    <h6 className='text-[12px] text-slate-700 font-[600] flex items-center'><RiCustomerService2Line size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Total Calls </h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.total_calls))?.split('.')[0].slice(1)}</h6>
                  </div>
            </div>     
          </div>
        </div> 

        
      </div>
    </div>
    
  )
}

export default Dashboard