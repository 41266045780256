import { deleteRequest, get, post,put } from "../helpers/apihelpers";

export const CheckInService = async (data)=>{
    try {
      const result = await post(`api/attendance/create`, data);
      return result;
    } catch (err) {
      return err.response;
    }
}

export const CheckOutService = async (id,data)=>{
  try {
    const result = await put(`api/attendance/update/${id}`, data);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const UpdateAttendanceAdminService = async (id,data)=>{
  try {
    const result = await put(`api/attendance/update_admin/${id}`, data);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const DownloadAttendanceAdminService = async ({user,from_date,to_date,page})=>{
  try {
    const result = await get(`api/attendance/downloadExcel?user=${user}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const DeleteInOutService = async (id)=>{
  try {
    const result = await deleteRequest(`api/attendance/delete/${id}`);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const GetTodayCheckInService = async ()=>{
  try {
    const result = await get(`api/attendance/get_today`);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const GetFilterCheckInOutService = async ({user,from_date1,to_date1,page})=>{
  try {
    const result = await get(`api/attendance/filter?user=${user}&from_date=${from_date1}&to_date=${to_date1}&page=${page}`);
    return result;
  } catch (err) {
    return err.response;
  }
}