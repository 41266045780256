import React, { useEffect, useState } from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import {HiOutlineBuildingOffice} from 'react-icons/hi2';
import {RiListCheck} from 'react-icons/ri';
import {AiOutlineControl,AiOutlineUserAdd,AiOutlineDeleteColumn,AiOutlineUpload} from 'react-icons/ai';
import {GrDocumentUser} from 'react-icons/gr';


function SettingsMenu() {

  const {pathname} = useLocation()

  const [step,setstep] = useState(1)
  const navigator = useNavigate()

 

  const menu1 = [
    {name:'Our Department',icon:HiOutlineBuildingOffice,path:'/settings/department',id:1,color:''},
    {name:'Our Designation',icon:RiListCheck,path:'/settings/designation',id:1,color:''},
    {name:'Our Roles',icon:AiOutlineControl,path:'/settings/roles',id:1,color:''},
    {name:'User Attendance',icon:GrDocumentUser,path:'/settings/user_attendance',id:1,color:''},
  ] 

  const menu2 = [
    {name:'Delete Data',icon:AiOutlineDeleteColumn,path:'/settings/mass_delete',id:1,color:''},
    {name:'Upload Data',icon:AiOutlineUpload,path:'/settings/upload_data',id:1,color:''},
  ]
  

  const menu3 = [
    {name:'OnBoard Employee',icon:AiOutlineUserAdd,path:'/settings/onboard_list',id:1,color:''},
  ]

  
  useEffect(()=>{
    menu1?.filter((f)=>f.path === pathname)?.length > 0 ? setstep(1) : setstep(2)
  },[])


  


  


  

  return (
    <div >

        <div className='flex md:hidden mx-3 items-center mt-2'>
          <div onClick={()=>setstep(1)} className='relative cursor-pointer mr-2'>
            <h6 className={`mb-2  rounded-md  px-1.5 py-1.5 font-[500] w-full ${step === 1 && 'bg-[#f4f5f7]'} block md:hidden text-[12px]`}>Basic Menu</h6>
            {step === 1 && <span className='block md:hidden border-slate-800 border-2 h-6 rounded absolute -ml-1.5 top-0' ></span>}
          </div>
          <div onClick={()=>setstep(2)} className='relative cursor-pointer'>
            <h6 className={`mb-2  rounded-md  px-1.5 py-1.5 font-[500] w-full ${step === 2 && 'bg-[#f4f5f7]'}  block md:hidden text-[12px]`}>Controller Menu</h6>
            {step === 2 && <span className='block md:hidden border-slate-800 border-2 h-6 rounded absolute -ml-1.5 top-0' ></span>}
          </div>
        </div> 

        {step === 1 &&
        <div className='block md:hidden mb-4 mx-3  md:pt-4 mx-1'>
        <h6 className='mb-2 font-[700] hidden md:block text-[12px]'>Menu</h6>
            <div className='flex  md:block w-auto'>
            {menu1.map((m,i)=>(
                <div key={m?.path} className={`flex ${i === 0 && 'min-w-[120px]'}  pr-4 md:pr-0 items-center  my-1 hover:bg-[#f4f5f7] px-1.5 py-1.5 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[400] ml-2 text-[12px]'>{m.name}</span>
              </div>     
            ))}
            </div>
        </div>}

        {step === 3 &&
        <div className='block md:hidden border-t mx-3 pt-2 md:pt-4'>
            <h6 className='mb-2 font-[700] hidden md:block text-[12px]'>Access</h6>
            <div className='flex md:block w-auto'>

            {menu2.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 mr-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[400] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}
            </div>
        </div>}

        {step === 2 &&
        <div className='block md:hidden border-t mx-3 pt-2 md:pt-4'>
            <h6 className='mb-2 font-[700] hidden md:block text-[12px]'>Access</h6>
            <div className='flex md:block w-auto'>

            {menu3.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 mr-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[400] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}
            </div>
        </div>}

      
     
      <div className='hidden md:block mr-0 md:min-h-screen md:max-h-screen md:border-r  md:w-44 px-2'>
           

          <h1 className='hidden md:block ml-2 mb-2 font-[700] text-[12px] pt-3 mt-2'> Basic Options</h1>
           {menu1.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[400] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}

        <h1 className='hidden md:block ml-2 mb-2 font-[700] text-[12px] pt-3 border-t'>Application Data</h1>
           {menu2.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[400] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))} 

        <h1 className='hidden md:block ml-2 mb-2 font-[700] text-[12px] pt-3 border-t'>Controller Options</h1>
           {menu3.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[400] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))} 


           

       
      </div>

     

   
    </div>
  )
}

export default SettingsMenu