import React, { useEffect, useState } from 'react';
import { Routes,Route, useNavigate } from 'react-router-dom';


import MobileOTP from './folder/screens/auth/Mobile';
import ForgotPassword from './folder/screens/auth/ForgotPassword';
import UpdatePassword from './folder/screens/auth/UpdatePassword';
import './App.css';
import {
  fromLatLng, setKey,
} from "react-geocode";



import Login from './folder/screens/auth/Login';
import { routes } from './folder/components/routes/router';

import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {AiOutlineSetting} from 'react-icons/ai';
import {FiUser} from 'react-icons/fi';
import {MdOutlineSpaceDashboard} from 'react-icons/md';
import {SiGoogleadsense} from 'react-icons/si';

import {HiLogout} from 'react-icons/hi';
import {HiOutlineMenuAlt4} from 'react-icons/hi';
import {RiCustomerService2Fill} from 'react-icons/ri';
import { LogOutAction } from './folder/redux/actions/authAction';
import { Drawer } from 'antd';
import {FaCaretDown,FaCaretUp} from 'react-icons/fa';
import { CheckInService, CheckOutService, GetTodayCheckInService } from './folder/services/AttendanceServices';


function App() {

  const location = useLocation()
  const {pathname} = useLocation()
  const [checkIn,setcheckIn] = useState('')
  const [loader,setloader] = useState(false)
  const auth = useSelector(state=>state.Auth)
  const [drawer_menu,setdrawer_menu] = useState(false)
  const dispatch = useDispatch()

  const path = pathname.split('/')[1]
  


  const navigate = useNavigate()

  const [mobileOpen, setMobileOpen] = useState(false);
  const [attendanceOption,setattendanceOption] = useState(false)

  const disabled_drawer = ['/','/login','/forgot_password','/update_password','/password_reset','/privacy',]

  const userRoute = [
    { name: "Dashboard", to: "/dashboard", icon: MdOutlineSpaceDashboard ,size:18,roles:['bd_user','manager','hod','admin']},
    { name: "Allocate", to: "/allocate", icon: SiGoogleadsense ,size:18,roles:['hod','manager','bd_user']},
    { name: "Calls", to: "/calls", icon: RiCustomerService2Fill ,size:18,roles:['hod','manager','bd_user']},
    { name: "Profile", to: "/profile", icon: FiUser,size:19,roles:[]},
    { name: "Settings", to: "/settings", icon: AiOutlineSetting,size:16,roles:['admin','controller']},
  ];

  useEffect(()=>{
    if(!['','login','forgot_password']?.includes(path)){
    gettodayCheckInCheckOut()
    }
  },[path])

  console.log("path",path)


  function gotodashboard(){
    if(auth?.roles?.includes('hod') || auth?.roles?.includes('manager') || auth?.roles?.includes('bd_user') || auth?.roles?.includes('admin')){
      navigate('/dashboard')
    }else if(auth?.roles?.includes('finance_head') || auth?.roles?.includes('finance_manager')){
      navigate('/finance')
    }
  }

  async function checkinoutfunc(){
    setloader(true)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position){
         setKey('AIzaSyBmoP0njHe6YMjbWNaPHZDWWdRpWhJAjIw')
         fromLatLng(position?.coords?.latitude, position?.coords?.longitude)
          .then(async({ results }) => {
            console.log("results",results)
            await gettodayCheckInCheckOut().then(async(res)=>{
              console.log("res",res)
              if(res?.length > 0){
                let send_Data = {
                  log_out: new Date().toLocaleTimeString('en-US', { hour: 'numeric',minute: 'numeric',second:'numeric', hour12: true }),
                  log_out_location:results[0]?.formatted_address
                }
                const response =  await CheckOutService(res[0]?._id,send_Data)
                  if(response?.status == 200){
                    setloader(false)
                    gettodayCheckInCheckOut()
                    toast.success("Check Out Successful")
                  }
              }else{
                let send_Data = {
                              log_in: new Date().toLocaleTimeString('en-US', { hour: 'numeric',minute: 'numeric',second:'numeric', hour12: true }),
                              log_in_location:results[0]?.formatted_address
                            }
                  const response =  await CheckInService(send_Data)
                  if(response?.status == 201){
                    setloader(false)
                    gettodayCheckInCheckOut()
                    toast.success("Check In Successful")
                  }
              }
            })
           
          })
          .catch(console.error);
      },function(){
         toast.error("Please enable location for Check In & Check out")
      }, {maximumAge:60000, timeout:5000, enableHighAccuracy:true})
        
    }else{
      toast.error("Oops Something error")
    }
  }

  const handleLogout = () => {
    dispatch(LogOutAction())
    navigate("/",{ replace: true });
  };

  const gettodayCheckInCheckOut = async() =>{
     const response = await GetTodayCheckInService()
     if(response?.data?.datas?.length > 0){
      setcheckIn(response?.data?.datas[0])
     }else{
      if(path === 'dashboard'){
        setattendanceOption(true)
      }
     }
     return response?.data?.datas
  }



  const drawer = (
    <div className={`relative bg-amber-400 min-h-screen `}>
      <div className='grid place-items-center py-3'>
      <img onClick={gotodashboard} className='invert w-6 h-6 flex items-center opacity-50 hover:opacity-100' src="https://fidecrmfiles.s3.amazonaws.com/fcpl_white_logo.png" />
      </div>
      {userRoute?.map((item) => (
         <React.Fragment key={item?.to}>
        
            <div key={item?.name}>
              <div className={`grid py-1.5 place-items-center  text-black  mb-1 cursor-pointer ${path === item?.to?.split('/')[1] ? 'opacity-100 text-black bg-white ' : 'opacity-70'}  ${path === item?.to?.split('/')[1] && 'opacity-100'} `} onClick={()=>{navigate(item.to);setMobileOpen(false)}}>
                {item.icon && <item.icon size={item.size}  /> }
                <span className="mt-1 text-[9px] font-[600]">
                  {item.name} 
                </span>
              </div>
            
            </div>  
            
          </React.Fragment>   
      ))}

      <div className="grid  bottom-0 py-2 border-white place-items-center opacity-70 hover:opacity-100 text-black  mb-1 cursor-pointer " onClick={handleLogout}>
          <HiLogout size={14}  /> 
          <span className="mt-1 text-[9px] font-[600]">
            {'Log out'}
          </span>
      </div>       
    </div>
  );

  console.log("path",path)

  return (
    <div className='dark w-screen overflow-x-hidden'>
   
  
      <div className="relative  md:flex w-full border-box">
  
  
  
  
  
          {!disabled_drawer.includes(location.pathname) && (
          <>
            <div className='hidden md:block min-w-[70px]  w-[70px] max-w-[70px]'>
              {drawer}
            </div>
          </>
         )}

         {!disabled_drawer.includes(location.pathname) && (
         <div className='md:hidden flex justify-between items-center bg-amber-400 text-white py-2.5 px-2'>
          <div className='flex items-center'>
            <img onClick={gotodashboard} className='w-6 invert h-6 flex items-center -mt-0.5 opacity-50 hover:opacity-100' src="https://fidecrmfiles.s3.amazonaws.com/fcpl_white_logo.png" />
            <h6 className='ml-4 text-[12.5px] tracking-wider text-[#000] uppercase font-[600]'>Dashboard</h6>
          </div>
          <HiOutlineMenuAlt4  className='text-[#000]' size={20} onClick={()=>setdrawer_menu(!drawer_menu)} /> 
         </div>)}

         <Drawer onClose={()=>setdrawer_menu(false)} maskClosable={true} open={drawer_menu} closable={false} className='-p-[5%]' width={'80%'}>
          <div>
          {/* <div className='md:hidden flex items-center bg-amber-400 text-white py-2.5 px-2'>
            <img onClick={gotodashboard} className='w-6 h-6 flex items-center opacity-50 hover:opacity-100' src="https://fidecrmfiles.s3.amazonaws.com/fcpl_white_logo.png" />
            <h6 className='ml-4 text-[14px] tracking-wider font-[600]'>Menu List</h6>
          </div>  */}

          <h6 className='p-2 bg-slate-100 text-[12px]  mb-4'>See the menu option list which was created for you for your daily work.</h6>

          {/*  */}
           {userRoute?.map((item)=>(
             <div className={`flex  px-1 py-1 text-slate-700  place-items-center  mb-1 cursor-pointer ${path === item?.to?.split('/')[1] ? 'opacity-100 ' : 'opacity-30'}  ${path === item?.to?.split('/')[1] && 'opacity-100'} `} onClick={()=>{setdrawer_menu(false);navigate(item.to);setMobileOpen(false)}}>
             {/* {item.icon && <item.icon size={16}  /> } */}
             <span className="mt-1 ml-3 uppercase text-[11.5px] font-[600]">
               {item.name} 
             </span>
           </div> 
           ))}

            <div className={`flex  absolute bottom-2  px-1 py-1 mx-2 text-black  place-items-center  mb-1 cursor-pointer opacity-50 `} onClick={()=>{setdrawer_menu(false);handleLogout()}}>
             {/* {<HiLogout size={16}  /> } */}
             <span className="mt-1 ml-3 uppercase text-[11.5px] font-[600]">
               Log Out 
             </span>
           </div> 
          </div>
         </Drawer>



  
  
       
  
        {/* <AppBar /> */}
  
       
        {/* <h1 className='ml-20 p-2 bg-amber-400' onClick={()=>toast.success('Hello Im here')}>Amma</h1> */}
  
       
  
        <div className={` bg-gray-0 h-screen relative md:min-w-[95vw] md:w-[95vw] overflow-x-hidden`}>
          <Toaster  position="bottom-center" toastOptions={{
            success: {
              style:{
                background:'black',
                color:'white',
                padding:'4px 7px',
                fontSize:'12px',
                borderRadius:'2px',
                iconTheme: {
                  fontSize:'4px'
                }
              }
            },
            error: {
              style:{
                background:'black',
                color:'white',
                padding:'4px 7px',
                fontSize:'12px',
                borderRadius:'2px',
                iconTheme: {
                  fontSize:'4px'
                }
              }
            }
          }}  />
  
           
       
  

        
          <Routes>
            {routes.map((screen,index) => (
              <Route key={index} exact path={screen.path}  element={screen.component} />
            ))}
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot_password" element={<MobileOTP />} />
            <Route exact path="/password_reset?" element={<ForgotPassword />} />
            <Route exact path="/update_password" element={<UpdatePassword />} />
          </Routes>

          {!['','login','forgot_password']?.includes(path) &&
      <>
      <FaCaretUp onClick={()=>setattendanceOption(!attendanceOption)} size={25} className=' bg-amber-400 absolute bottom-[0px] right-[11.5%] px-1.5 py-1 h-[20px] cursor-pointer text-black' />

      {attendanceOption && 
      <div onMouseLeave={()=>{}}  className='w-[250px] p-3 min-h-[200px] bg-sky-50 max-h-[200px] border-box  absolute bottom-0 right-[50px] '>
        <FaCaretDown onClick={()=>{setattendanceOption(!attendanceOption)}} size={25} className=' bg-amber-400 absolute -top-[21px] left-[44%]  px-1.5 py-1 h-[20px] cursor-pointer text-black' />
         <h6 className='text-[13px] font-[600]'>Check In / Check Out</h6>
         <h6 className='text-[10px] font-[400] mt-2 opacity-80'>use the below button to check in and checkout for your daily attendance report</h6>

         <div className='flex items-center justify-between mt-4 border-t border-b border-slate-400'> 
          <div className='flex-col items-center justify-center p-2 w-[50%] border-r border-slate-400'>
            <h6 className='text-[11px] opacity-80'>Check In Time</h6>
            <h6 className='text-[12px] mt-1  font-[600]'>{checkIn?.log_in === undefined ? 'Not Added' : checkIn?.log_in}</h6>
          </div>
          <div className='text-[11px] p-2 w-[50%] pl-3 border-slate-400'>
            <h6 className='text-[11px] opacity-80'>Check Out Time</h6>
            <h6 className='text-[12px] mt-1  font-[600]'>{checkIn?.log_out === undefined ? 'Not Added' : checkIn?.log_out}</h6>
          </div>
        
        </div> 
        
         <div className='flex cursor-pointer items-center justify-center mt-10'>
          {loader ?  
           <h6  className='bg-black w-[80px] text-center text-white p-1.5 rounded text-[11px] uppercase'>Loading</h6>
             :
            <h6 onClick={checkinoutfunc} className='bg-black w-[80px] text-center text-white p-1.5 rounded text-[11px] uppercase'>Check {checkIn === '' ? 'In' : 'Out'}</h6>}
         </div>

      </div>}  
      </>}
        </div>
        </div>

        </div>
  );
}

export default App;
