// import { Typography } from '@mui/material';
// import TextField from '@mui/material/TextField';
// import { Box } from '@mui/system';
import {BiErrorCircle} from 'react-icons/bi'

export const TextInput = (props) =>{
    return (
    <div>
    <div className={`${props.label !== '' && 'mt-2'}`}>
   {props.label !== '' && <h6 className='text-[11px] font-[400] mb-1' >{props.label}</h6>}
    <input readOnly={props.readOnly} type={props.type} className={`inline-block border-[#d0d7de] focus:ring-0 outline-none border py-1.5 px-2  w-full text-[400] text-[12px] ${props.readOnly ? 'opacity-50' : 'opacity-100'} ${props.mandatory && 'border-l-4 border-l-slate-600 '}`} name={props.name} value={props.value} onChange={props.handlechange}  />
    </div>
    {props.error !== '' && props.error !== undefined &&
    <div className='flex items-center mt-1'>
    <BiErrorCircle className='text-red-500' size={14} />
    <span className='text-[10px] text-red-500 ml-1'>{props.error}</span>
    </div>}
    </div>
)}


export const TextInput1 = (props) =>{
    return (
    <div>
    <div className='mt-2'>
    <h6 className='text-[11px] font-[400] mb-1' >{props.label}</h6>
    <input readOnly={props.readOnly} type="text" className={`inline-block border-[#d0d7de] focus:ring-0 outline-none border py-1.5 px-2 w-full  text-[400] text-[12px] focus:bg-white ${props.readOnly ? 'opacity-50' : 'opacity-100'} `} name={props.name} value={props.value} onChange={props.handlechange}  />
    </div>
    {props.error !== '' && props.error !== undefined &&
    <div className='flex items-center mt-1'>
    <BiErrorCircle className='text-red-500' size={14} />
    <span className='text-[10px] text-red-500 ml-1'>{props.error}</span>
    </div>}
    </div>
)}

export const TextAreaInput1 = (props) =>{
    return (
    <div>
    <div className='mt-2'>
    <h6 className='text-[11px] font-[400] mb-1' >{props.label}</h6>
    <textarea readOnly={props.readOnly} type="text" placeholder={props?.placeholder} className={`max-w-full inline-block border-[#d0d7de] focus:ring-0 outline-none border py-1.5 px-2  w-full text-[12px]  ${props.readOnly ? 'opacity-50' : 'opacity-100'} ${props.mandatory && 'border-l-4 border-l-slate-600'}`} name={props.name} value={props.value} onChange={props.handlechange}  />
    </div>
    {props.error !== '' && props.error !== undefined &&
    <div className='flex items-center mt-1'>
    <BiErrorCircle className='text-red-500' size={14} />
    <span className='text-[10px] text-red-500 ml-1'>{props.error}</span>
    </div>}
    </div>
)}


