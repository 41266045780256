import React from 'react'
import MainprofileAppbar from './MainprofileAppbar'
import ProfileMenu from './ProfileMenu'
import { ButtonOutlined } from '../../components/button'
import {CgArrowLeftR} from 'react-icons/cg';

function Accessability() {
  return (
    <div className='px-2 mx-0 box-border  max-h-screen overflow-y-scroll overflow-x-hidden'>


    <div className='block sm:flex'>
    <ProfileMenu />

    <div className='md:ml-6  w-[92%] md:w-full ml-4 md:mt-4 md:pt-5'>
    <h6 className='border-b mt-1 pb-2 mb-4 font-[700] text-[17px] border-[#d0d7de]'>Accessability</h6>

    <h6 className='text-[16px] font-[400]'>
      Keyboard shortcuts
    </h6>

    <h6 className='text-[14px] mt-4 font-[400]'>
    Command palette
    </h6>
    <h6 className='mt-2 flex items-center  text-[12px] text-gray-500 font-[400]'>
    Modify the shortcuts to trigger the Command Palette for the default search mode and the command mode
    <CgArrowLeftR className='ml-2' />
    </h6>

    <div className='mt-3'>
    <ButtonOutlined btnName="No shortcuts enabled" />
    </div>

    
    </div>
    </div>
    </div>
  )
}

export default Accessability