import { deleteRequest, get, postfd, put } from "../helpers/apihelpers";

export const CreateLandAllocateAttachmentService=async(data)=>{
    try {
        const result = await postfd(`api/land_allocate_attachment/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateLandAllocateAttachmentService=async(data,id)=>{
    try {
        const result = await postfd(`api/land_allocate_attachment/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetLandAllocateAttachmentService=async(id)=>{
    try {
        const result = await get(`api/land_allocate_attachment/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}



export const DeleteLandAllocateAttachmentService=async(id)=>{
    try {
        const result = await deleteRequest(`api/land_allocate_attachment/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

