import React from 'react'
import ProfileMenu from './ProfileMenu'
import { ButtonOutlinedAutoWidth } from '../../components/button'

function UserOrganization() {
  return (
    <div className='max-h-screen overflow-y-scroll overflow-x-hidden px-2 mx-0 box-border '>


    <div className='block sm:flex'>
    <ProfileMenu />

    <div className='w-full'>
    <div className='md:ml-6 w-[94%] md:w-4/6 ml-4 mt-4 pt-5'>
    <h6 className='border-b mt-1 pb-2 mb-4 font-[700] text-[17px] border-[#d0d7de]'>Your Organization</h6>

    <h6 className='text-[13px] text-gray-[700]'>Your the Member of <span className='font-[800]'> Fidelitus Corp Family </span> and your account is safe and secure with them.</h6> 
    
    <div className='mt-2'>
    <ButtonOutlinedAutoWidth btnName="Transfer Account" />  
    </div> 
    </div>
    </div>
    </div>
    </div>  
  )
}

export default UserOrganization