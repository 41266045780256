import { Tooltip } from '@mui/material'
import React from 'react'
// import {FiUpload} from 'react-icons/fi'
import {AiOutlineClose} from 'react-icons/ai'
// import { theme_line, theme_line_dark } from '../constants/ThemeConstants'
// import { image_base_url } from '../constants/UrlConstants'
import {BiErrorCircle} from 'react-icons/bi'

function Uploader({image,setimagefunc,name,removeimageuploadfunc,required,label,error}) {

 
  function setimage(v){
    setimagefunc(v)
  }

  // {target:{files}}

 if(image === '' || image == null){
  return (
    <>
    {label !== '' && <h6 className='text-[11px] font-[400] mb-1' >{label}</h6>}
    <form onClick={()=>document.querySelector(`input[name="${name}"]`).click()} className={`${required && 'border-l-4 border-l-slate-600 '} p-[13px] flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
        <input type='file' name={name}  onChange={(e)=>{
          e.target.files[0] && setimagefunc(e.target.files[0])
          // console.log("e here anna",e.target.files)
        }} accept="*" className='input-field' hidden />
    </form> 
    {error !== '' && error !== undefined &&
    <div className='flex items-center mt-1'>
    <BiErrorCircle className='text-red-500' size={14} />
    <span className='text-[10px] text-red-500 ml-1'>{error}</span>
    </div>}
    </>
  )}
  else{
    return (
     <>
      {label !== '' && <h6 className='text-[11px] font-[400] mb-1' >{label}</h6>}
      <div className={`p-[6px] ${required && 'border-l-4 border-l-slate-600 '} border border-slate-300 relative flex flex-col  cursor-pointer`}>
          <Tooltip title='Delete'><AiOutlineClose size={10} className='absolute top-2 right-2' onClick={removeimageuploadfunc}/></Tooltip>
          <h6 className='text-[12px] truncate w-48 ml-0'>{image?.name !== undefined ? image?.name : image}</h6>
      </div>
      {error !== '' && error !== undefined &&
      <div className='flex items-center mt-1'>
      <BiErrorCircle className='text-red-500' size={14} />
      <span className='text-[10px] text-red-500 ml-1'>{error}</span>
      </div>}
      </>
      
    )
  }
}

export default Uploader