import React, { useState,createRef } from 'react'
import { useNavigate } from 'react-router-dom';

import { TextInput } from '../../components/input';
import { RequestOtpService, VerifyOtpService } from '../../services/AuthServices';
import {BiErrorCircle} from 'react-icons/bi'
import { toast } from 'react-hot-toast';




export default function MobileOTP() {

  
  const ref = createRef()
  const ref1 = createRef()
  const ref2 = createRef()
  const ref3 = createRef()
  const ref4 = createRef()
  const ref5 = createRef()

  const navigate = useNavigate()

  const [data,setdata] = useState({official_email:'',otp1:'',otp2:'',otp3:'',otp4:'',otp5:'',otp6:''})
  const [error,seterror] = useState({official_email:'',otp:''})
  const [step,setstep] = useState(1)




  function handlechange(e){
    
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
    if(step === 1){
      seterror({...error,[e.target.name]:''})
    }else if(step === 2){
      seterror({...error,otp:''})
    }
    if(e.target.name === 'otp1'){
      if(e.target.value !== ''){
        ref1.current.focus()
      }else{
        ref.current.focus()
      }
    }
    if(e.target.name === 'otp2'){
      if(e.target.value !== ''){
        ref2.current.focus()
      }else{
        ref1.current.focus()
      }
    }
    if(e.target.name === 'otp3'){
      if(e.target.value !== ''){
        ref3.current.focus()
      }else{
        ref2.current.focus()
      }
    }
    if(e.target.name === 'otp4'){
      if(e.target.value !== ''){
        ref4.current.focus()
      }else{
        ref3.current.focus()
      }
    }
    if(e.target.name === 'otp5'){
      if(e.target.value !== ''){
        ref5.current.focus()
      }else{
        ref4.current.focus()
      }
    }
    if(e.target.name === 'otp6'){
      if(e.target.value !== ''){
        // ref4.current.focus()
      }else{
        ref5.current.focus()
      }
      // handleSubmit()
    }
  }

  function onKeyDownHandler(e){
    if (e.keyCode === 13) {
      handleSubmit();
    }
  }
  

  const handleSubmit = async () => {
   
    if(step === 1){
      if(!data.official_email){
        seterror({...error,official_email:'Enter a official email *'})
      }else if(!data.official_email.toLowerCase().includes('@fidelituscorp.com')){
        seterror({...error,official_email:'Invalid email Id *'})
      }
      else{
        const response =  await RequestOtpService(data)
        if(response.status === 200){
           setdata({official_email:''})
           seterror({official_email:''})
           setstep(2)
        }else if(response.status === 404){
          toast.error(response?.data?.errors)
          seterror({...error,official_email:response?.data?.error})
        }
      }
    }else if(step === 2){
      if((data.otp1 !== '' && data.otp2 !== '' && data.otp3 !== '' && data.otp4 !== '' && data.otp5 !== '' && data.otp6 !== '') === false){
        seterror({...error,otp:'Enter a valid 6 digit OTP*'})
      }else{
        let send_data = {}
        send_data['official_email'] = data.official_email
        send_data['otp'] = data.otp1 + data.otp2 + data.otp3 + data.otp4 + data.otp5 + data.otp6
        const response = await VerifyOtpService(send_data)
        if(response.status === 200){
          // toast.success(response?.data?.message)
          navigate('/forgot_password',
                {state: { datas: {official_email:data.official_email,otp:data.otp1 + data.otp2 + data.otp3 + data.otp4 + data.otp5 + data.otp6} }}
          )
          setdata({...data,mobile:'',otp:'',otp1:'',otp2:'',otp3:'',otp4:'',otp5:'',otp6:''})
        }
        if(response.status === 403){

          seterror({...error,otp:response?.data?.error})
        }
      }
    }
  };


  return (
   

    <div className='flex overflow-y-hidden items-center h-screen min-h-screen max-h-screen justify-center md:justify-between'>

     <div className='hidden md:block min-w-[70vw] w-[70vw] max-w-[70vw]  bg-amber-400'>
     <img src="https://fidecrmfiles.s3.amazonaws.com/crmposter.avif" className='w-full h-full object-cover opacity-20' />
    </div>   

    {/* <div className='flex justify-center pb-5 items-center box-border'> 
        <img alt="logo" src={fidelogo} style={{width:'30px',height:'30px',objectFit:'contain'}} />
        <h6 className='text-[16px] font-[600] text-center ml-2  font-family:Roboto-300'>Fidelitus</h6>
    </div>    */}


   

    {/* {(error?.user_id !== '' || error?.password !== '')  &&
    <div className='bg-[#ffebe8] p-4 flex items-center border border-[#ffc1c1] rounded-md mb-5 justify-between'>
        <h6 className='text-[13.5px] font-[400]'>{error?.user_id}{error?.password}</h6>
        <IoClose size={18} className="text-[#cf212e] cursor-pointer" onClick={()=>seterror({user_id:'',password:''})} />
    </div>}

    {stateMsg !== ''  &&
    <div className='bg-[#ddf4ff] p-4 flex items-center border border-[#a8d8ff] rounded-md mb-5 justify-between'>
        <h6 className='text-[13.5px] font-[400]'>{stateMsg}</h6>
        <IoClose size={18} className="text-[#5ebcff] cursor-pointer" onClick={()=>setstateMsg('')} />
    </div>} */}


    <div className='min-w-[70vw] w-[70vw] max-w-[70vw]  md:min-w-[30vw] md:w-[30vw] md:max-w-[30vw] flex justify-center items-center' >

    
        <div className='p-1 w-72'  >  


      

<h6 className={`${step === 1 ?  'text-[16px] w-3/4 font-[800] ' : 'text-[13px] w-full font-[500] ' }  mb-2 leading-normal`} >{step === 1 ? "Enter your email address" : 'Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.'}</h6>
{step === 1  && <h6 className={`text-[10px] font-[500] bg-slate-100 p-2 leading-normal  my-2`} >{step === 1 ? "Enter your user account's verified email address and we will send you a password reset link." : ''}</h6>}
{/* <h6 className='text-[10px] bg-sky-50 p-2 font-semibold leading-snug' >{step === 1 ? 'Add your email added during creation of fidelitus account' : 'Enter your otp sent to your mobile no'  + <b>{data.mobile}</b>}</h6> */}

<form  onKeyDown={onKeyDownHandler}
             onSubmit={handleSubmit}>
{step === 1 ?
<div className='pt-2'>
<TextInput 
     label={''}  
     variant="standard"
     name="official_email"
     type="text"
     error={error.official_email}
     value={data.official_email}
     handlechange={handlechange}
     placeholder="Enter your Email"
     InputLabelProps={{
         style: { color: '#fff', }, 
     }}/>
 </div>  
:
 <div className='mt-2'>
    {/* <div className='flex justify-between mt-5'>
    <input type="text" ref={ref} maxLength='1' className='border py-1.5 px-1.5 mx-1 rounded w-8 text-sm flex text-center' name='otp1' value={data.otp1} onChange={handlechange}   />
    <input type="text" ref={ref1}  maxLength='1' className='border py-1.5 px-1.5 mx-1 rounded w-8 text-sm flex text-center' name='otp2' value={data.otp2} onChange={handlechange}   />
    <input type="text" ref={ref2} maxLength='1' className='border py-1.5 px-1.5 mx-1 rounded w-8 text-sm flex text-center' name='otp3' value={data.otp3} onChange={handlechange}   />
    <input type="text" ref={ref3} maxLength='1' className='border py-1.5 px-1.5 mx-1 rounded w-8 text-sm flex text-center' name='otp4' value={data.otp4} onChange={handlechange}   />
    <input type="text" ref={ref4} maxLength='1' className='border py-1.5 px-1.5 mx-1 rounded w-8 text-sm flex text-center' name='otp5' value={data.otp5} onChange={handlechange}   />
    <input type="text" ref={ref5} maxLength='1' className='border py-1.5 px-1.5 mx-1 rounded w-8 text-sm flex text-center' name='otp6' value={data.otp6} onChange={handlechange}   />
    </div> */}

 </div>
 
 }

 {error.otp !== '' && error.otp !== undefined && 
 <div className='flex items-center mt-1'>
 <BiErrorCircle className='text-red-500' size={14} />
 <span className='text-[10px] text-red-500 ml-1'>{error.otp}</span>
 </div>}




 {/* <button onClick={handleSubmit} className="bg-[#158a93] text-white  font-bold py-1.5 px-4 mt-5 rounded w-full text-[13px]">
 {step === 1 ?  'SEND OTP' : 'VERIFY OTP'} 
 </button> */}


 <div className={`mt-4 ${(data?.official_email) ? 'opacity-100' : step !== 2 ? 'opacity-25' : 'opacity-100'}`}>
 {step === 1 ?
  <h6 onClick={handleSubmit} className="cursor-pointer bg-amber-400 text-center mt-4 font-[500] py-[8px] px-4 mt-0  w-full rounded-md border-slate-600  text-[13px] hover:border-amber-500">Send password reset email</h6>
 :
 <h6  onClick={()=>navigate(-1)}  className="cursor-pointer bg-amber-400 text-center mt-4 text-white font-[500] py-[8px] px-4 mt-0  w-full rounded-md border-slate-600  text-[14px] hover:border-slate-600">Return to sign in</h6>
 }
 </div>
 </form>

 
 <h6 className='text-[10px] mt-4 text-800 leading-normal'>By continuing, you agree to Fidelitus<span className='text-black font-bold'> Conditions of Use</span>  and <span className='text-black font-bold'> Privacy Notice </span> .</h6>


 <h6 className='text-[10px] mt-4 text-800  text-center'>Don't want to update password you can use the below button to cancel.</h6>


 <button onClick={()=>navigate(-1)} className="w-full  py-2  mt-5 rounded  text-[12px]  cursor-pointer">
   Go Back 
 </button>
        </div>  
    </div> 

    {/* <Card variant='outlined' sx={{textAlign:'center',padding:2,marginTop:2}} className="border-[#d7dee3] border">
        <h6 className='text-[13px] font-[500]'>New to Fidelitus? <span className='text-[#0a69da] cursor-pointer'> Create an account.</span></h6>
    </Card> */}
    {/* <Copyright sx={{ mt: 8, mb: 4 }} className="center w-full" /> */}
    </div>  
  );
}