import React, { useEffect, useState } from 'react'
import AllocateMenu from './AllocateMenu'
import { TextAreaInput1, TextInput } from '../../components/input'
import {BiCheckbox,BiCheckboxSquare,BiErrorCircle} from 'react-icons/bi'
import {AiOutlineCloudUpload} from 'react-icons/ai'
import Uploader from '../../components/Uploader'
import {FiUser} from 'react-icons/fi'
import {BiLandscape} from 'react-icons/bi'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import toast from 'react-hot-toast'
import { CreateLandAllocateService, GetLandAllocateServiceDetail, UpdateLandAllocateService } from '../../services/LandAllocateServices'
import { useLocation, useNavigate } from 'react-router-dom'
import { Select } from 'antd'
import ErrorComponent from '../../components/ErrorComponent'
import { GetLandAllocateAttachmentService } from '../../services/LandAllocateAttachmentServices'

function AllocationSiteCE() {


  const {state} = useLocation()
  const navigator = useNavigate()

  const category = [{label:'A',value:'A'},{label:'B',value:'B'},{label:'C',value:'C'}]


  const attachment = {name:'',file:''}
  const [attachments,setattachments] = useState([])
  // const [attachment_error,setattachment_error] = useState({name:'',file:''})


  // console.log("attachments",attachments)

  useEffect(()=>{
       if(state !== null && state !== undefined && state !== null){
          getData(state)
          getAttachmentData(state)
       }
  },[])


  async function getData(v){
      const response = await GetLandAllocateServiceDetail(v)
      let d = response?.data?.datas[0]
      setdata({...d,registration_date:d?.registration_date?.slice(0,10)})
      // console.log("response",response)
  }

  async function getAttachmentData(){
    const response = await GetLandAllocateAttachmentService(state)
    let d = response?.data?.data
    setattachments(d)
  }


  const [step,setstep] = useState(1)

  const [data,setdata] = useState(
    {
      name:'',
      mobile:'',
      email:'',
      address:'',
      aadhar_no:'',
      pan_no:'',
      photo:'',
      aadhar_file:'',
      pan_file:'',
      dl_file:'',
      site_no:'',
      site_info:'',
      muda_docs:'',
      stage:'',
      survey_no:'',
      village:'',
      extent:'',
      category:'',
      registration_date:'',
      category:'',
      katha:false,
      sales_deed:'',
      secondary_name:'',
      secondary_contact:'',
      comments:'',
      noc:'',
      voter_id:'',
      ration_card:'',
      purchased_from:'',
      date_of_contact:''
    }
  )
  const [error,seterror] = useState(
    {
      name:'',
      mobile:'',
      email:'',
      address:'',
      aadhar_no:'',
      pan_no:'',
      photo:'',
      aadhar_file:'',
      pan_file:'',
      dl_file:'',
      site_no:'',
      site_info:'',
      muda_docs:'',
      stage:'',
      survey_no:'',
      village:'',
      extent:'',
      category:'',
      registration_date:'',
      category:'',
      katha:false,
      sales_deed:'',
      secondary_name:'',
      secondary_contact:'',
      comments:'',
      noc:'',
      voter_id:'',
      ration_card:'',
      purchased_from:'',
      date_of_contact:''
    }
  )

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
  }

  function goBack(){
    if(step === 2){
      setstep(1)
    }else{
      navigator(-1)
    }
  }

  async function submitData(){
    if(step === 1){
      if(!data?.name){
        seterror({...error,name:'This Field is required*'})
      }else if(!data?.mobile || data?.mobile?.length !== 10){
        seterror({...error,mobile:'Enter a valid 10 digit mobile no*'})
      }else if(!data?.photo){
        seterror({...error,photo:'This Field is required*'})
      }else if(!data?.sales_deed){
        seterror({...error,sales_deed:'This Field is required*'})
      }else if(!data?.noc){
        seterror({...error,noc:'This Field is required*'})
      }else{
        setstep(2)
      }
    }else if(step === 2){
      if(!data?.site_no){
        seterror({...error,site_no:'This Field is required*'})
      }else if(!data?.registration_date){
        seterror({...error,registration_date:'This Field is required*'})
      }else if(!data?.survey_no){
        seterror({...error,survey_no:'This Field is required*'})
      }else if(!data?.village){
        seterror({...error,village:'This Field is required*'})
      }else if(!data?.extent){
        seterror({...error,extent:'This Field is required*'})
      }else if(!data?.purchased_from){
        seterror({...error,purchased_from:'This Field is required*'})
      }else if(!data?.date_of_contact){
        seterror({...error,date_of_contact:'This Field is required*'})
      }else if(!data?.category){
        seterror({...error,category:'This Field is required*'})
      }else{
        setstep(3)
      }
     } else if(step === 3){
        console.log("1")
        const fd = new FormData()
        fd.append('name',data.name)
        fd.append('mobile',data.mobile)
        fd.append('email',data.email)
        fd.append('address',data.address)
        fd.append('aadhar_no',data.aadhar_no)
        fd.append('pan_no',data.pan_no)
        data?.photo?.name !== undefined &&  fd.append('photo',data.photo)
        data?.aadhar_file?.name !== undefined &&  fd.append('aadhar_file',data.aadhar_file)
        data?.pan_file?.name !== undefined &&  fd.append('pan_file',data.pan_file)
        data?.dl_file?.name !== undefined && fd.append('dl_file',data.dl_file)
        data?.sales_deed?.name !== undefined && fd.append('sales_deed',data.sales_deed)
        data?.noc?.name !== undefined && fd.append('noc',data.noc)
        data?.voter_id?.name !== undefined && fd.append('voter_id',data.voter_id)
        data?.ration_card?.name !== undefined && fd.append('ration_card',data.ration_card)
        fd.append('site_no',data.site_no)
        fd.append('village',data.village)
        fd.append('survey_no',data.survey_no)
        fd.append('registration_date',data.registration_date)
        fd.append('katha',data.katha  ? '1' : '0')
        fd.append('category',data.category.toUpperCase())
        fd.append('extent',data.extent)
        fd.append('purchased_from',data.purchased_from)
        fd.append('date_of_contact',data.date_of_contact)

        // console.log("data.katha",data.katha)

        data?.muda_docs?.name !== undefined && fd.append('muda_file',data.muda_docs)
        fd.append('secondary_contact',data.secondary_contact)
        fd.append('secondary_name',data.secondary_name)
        fd.append('comments',data.comments)

        attachments.forEach((e,i) => {
          if(e?.file !== '' && e?.file?.name !== undefined){
            fd.append('files',e?.file)
          }
        });
        

        if(state !== null && state !== undefined && state !== ''){
          


          const response = await UpdateLandAllocateService(fd,state)
          if(response.status === 201){
            toast.success(response?.data?.data)
            resetForm()
            setTimeout(() => {
                navigator(-1)
            }, 1500);

          }
        }else{
          const response = await CreateLandAllocateService(fd)
          if(response.status === 201){
            toast.success(response?.data?.data)
            resetForm()
          }
        }
    }
  }

  console.log("attachments",attachments)

  function resetForm(){
    setdata( {
      name:'',
      mobile:'',
      email:'',
      address:'',
      aadhar_no:'',
      pan_no:'',
      photo:'',
      aadhar_file:'',
      pan_file:'',
      dl_file:'',
      site_no:'',
      site_info:'',
      muda_docs:'',
      stage:'',
      survey_no:'',
      village:'',
      extent:'',
      registration_date:'',
      category:'',
      katha:false,
      extent:'',
      sales_deed:'',
      secondary_name:'',
      secondary_contact:'',
      comments:'',
      noc:'',
      voter_id:'',
      ration_card:'',
      purchased_from:'',
      date_of_contact:''
    })

    seterror( {
      name:'',
      mobile:'',
      email:'',
      address:'',
      aadhar_no:'',
      pan_no:'',
      photo:'',
      aadhar_file:'',
      pan_file:'',
      dl_file:'',
      site_no:'',
      site_info:'',
      muda_docs:'',
      stage:'',
      survey_no:'',
      village:'',
      extent:'',
      registration_date:'',
      category:'',
      katha:false,
      extent:'',
      sales_deed:'',
      secondary_name:'',
      secondary_contact:'',
      comments:'',
      noc:'',
      voter_id:'',
      ration_card:'',
      purchased_from:'',
      date_of_contact:''
    })

    setattachments([])

    setstep(1)
  }


  function removeimage(i){
    let oldData = [...attachments]
    oldData.splice(i,1)

    setattachments(oldData)
  }

  function setfiles(e,i){
    let oldData = [...attachments]
    oldData[i]['file'] = e
    oldData[i]['name'] = e.name
    setattachments([...oldData])
  }

  // console.log("data",data)

  return (
    <div className='md:pr-5 min-h-screen max-h-screen h-screen overflow-y-scroll'>
      
      <div className='block md:flex w-full'>
       <AllocateMenu />   
       <div className='w-full md:border-r md:min-w-[170px] md:max-w-[170px] p-2 pt-3'>
             
             <h6 className='hidden md:block ml-2 mb-2 font-[700] text-[12px] mt-2'>Form Steps</h6>
             
               
              <div className='flex md:block w-full space-evenly'>
                <div  className={`flex w-[50%] w-[94%] items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-2.5  md:py-1 ml-2 rounded-md relative cursor-pointer ${step ===  1 && 'bg-[#f4f5f7]'}`} onClick={()=>setstep(1)}>
                  {step ===  1 &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <FiUser color='#000' style={{backgroundColor:'border-slate-800',padding:'2px'}} size={16}  />
                  <span className='text-[#000] font-[400] ml-2 text-[12px]'>{'Customer Info'}</span>
                </div>  

                <div  className={`flex w-[50%] w-[94%] items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-2.5 md:py-1 ml-2 rounded-md relative cursor-pointer ${step ===  2 && 'bg-[#f4f5f7]'}`} onClick={()=>setstep(2)}>
                  {step ===  2 &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <BiLandscape color='#000' style={{backgroundColor:'border-slate-800',padding:'2px'}} size={16}  />
                  <span className='text-[#000] font-[400] ml-2 text-[12px]'>{'Land Info'}</span>
                </div>

                <div  className={`flex w-[50%] w-[94%] items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-2.5 md:py-1 ml-2 rounded-md relative cursor-pointer ${step ===  3 && 'bg-[#f4f5f7]'}`} onClick={()=>setstep(3)}>
                  {step ===  3 &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <AiOutlineCloudUpload color='#000' style={{backgroundColor:'border-slate-800',padding:'2px'}} size={16}  />
                  <span className='text-[#000] font-[400] ml-2 text-[12px]'>{'More Docs'}</span>
                </div>   
              </div> 

        </div> 
       <div className='p-4 md:w-[40%]'>
            <div className='border-b   pb-4 mb-4'>
                <h6 className='font-[600] text-[15px] mb-1'>Cread / Update the Land Allocated</h6> 
                <h6 className='text-[11px] mt-2 leading-tight font-[400] p-2 bg-slate-100 '>Use the below form to create or edit the land allocated for your customer reference to get the data added  by you all the time.</h6>

               {step === 1 &&  
                <div className='block md:flex '>
                <div className='w-[100%] md:w-[50%] md:mr-2'>
                   <div>
                   <TextInput 
                      mandatory={true}
                      label={'Name'}  
                      variant="standard"
                      name="name"
                      type="text"
                      value={data.name}
                      error={error.name}
                      handlechange={handlechange}
                      placeholder="Enter  name"
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div>  

                   <div>
                   <TextInput 
                      mandatory={true}
                      label={'Mobile'}  
                      variant="standard"
                      name="mobile"
                      type="text"
                      value={data.mobile}
                      error={error.mobile}
                      handlechange={handlechange}
                      placeholder="Enter contact name"
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div>  

                   <div>
                   <TextInput 
                      mandatory={false}
                      label={'Email'}  
                      variant="standard"
                      name="email"
                      type="text"
                      value={data.email}
                      error={error.email}
                      handlechange={handlechange}
                      placeholder="Enter contact name"
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div>  

                   <div>
                   <TextAreaInput1 
                      mandatory={false}
                      label={'Address'}  
                      variant="standard"
                      name="address"
                      type="text"
                      value={data.address}
                      error={error.address}
                      handlechange={handlechange}
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div> 

                   <div>
                   <TextInput 
                      mandatory={false}
                      label={'Aadhar No'}  
                      variant="standard"
                      name="aadhar_no"
                      type="text"
                      value={data.aadhar_no}
                      error={error.aadhar_no}
                      handlechange={handlechange}
                      placeholder="Enter contact name"
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div>  

                   <div>
                   <TextInput 
                      mandatory={false}
                      label={'Pan No'}  
                      variant="standard"
                      name="pan_no"
                      type="text"
                      value={data.pan_no}
                      error={error.pan_no}
                      handlechange={handlechange}
                      placeholder="Enter contact name"
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div>

                    <div>
                   <TextInput 
                      mandatory={false}
                      label={'Secondary Name'}  
                      variant="standard"
                      name="secondary_name"
                      type="text"
                      value={data.secondary_name}
                      error={error.secondary_name}
                      handlechange={handlechange}
                      placeholder="Enter contact name"
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div>   

                   <div>
                   <TextInput 
                      mandatory={false}
                      label={'Secondary Contact'}  
                      variant="standard"
                      name="secondary_contact"
                      type="text"
                      value={data.secondary_contact}
                      error={error.secondary_contact}
                      handlechange={handlechange}
                      placeholder="Enter contact name"
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div>  


                    <div>
                   <TextAreaInput1 
                      mandatory={false}
                      label={'Comments'}  
                      variant="standard"
                      name="comments"
                      type="text"
                      value={data.comments}
                      error={error.comments}
                      handlechange={handlechange}
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div>  
                   

                  
                   
                </div> 

                <div className='w-[100%] md:w-[50%] md:ml-2 mt-2'>
                <Uploader error={error?.photo} name="photo" required={true} label={'Photo'} image={data?.photo}  setimagefunc={(e)=>{setdata({...data,photo:e});seterror({...error,photo:''})}}  removeimageuploadfunc = {()=>{setdata({...data,photo:''});seterror({...error,photo:''})}} />
                <div className='mt-2'>
                <Uploader error={error?.sales_deed} name="sales_deed" required={true} label={'Old Sales Deed'} image={data?.sales_deed}  setimagefunc={(e)=>{setdata({...data,sales_deed:e});seterror({...error,sales_deed:''})}}  removeimageuploadfunc = {()=>{setdata({...data,sales_deed:''});seterror({...error,sales_deed:''})}} />
                </div>

                <div className='mt-2'>
                <Uploader error={error?.noc} name="noc" required={true} label={'NOC'} image={data?.noc}  setimagefunc={(e)=>{setdata({...data,noc:e});seterror({...error,noc:''})}}  removeimageuploadfunc = {()=>{setdata({...data,noc:''});seterror({...error,noc:''})}} />
                </div>
               
                <div className='mt-2'>
                <Uploader required={false} name="aadhar_file" label={'Aadhar File'} image={data?.aadhar_file}  setimagefunc={(e)=>setdata({...data,aadhar_file:e})}  removeimageuploadfunc = {()=>setdata({...data,aadhar_file:''})} />
                </div>
                <div className='mt-2'>
                <Uploader required={false} name="pan_file" label={'Pan File'} image={data?.pan_file}  setimagefunc={(e)=>setdata({...data,pan_file:e})}  removeimageuploadfunc = {()=>setdata({...data,pan_file:''})} />
                </div>

                <div className='mt-2'>
                <Uploader required={false} name="dl_file" label={'Dl File'} image={data?.dl_file}  setimagefunc={(e)=>setdata({...data,dl_file:e})}  removeimageuploadfunc = {()=>setdata({...data,dl_file:''})} />
                </div>

                <div className='mt-2'>
                <Uploader required={false} name="voter_id" label={'Voter Id'} image={data?.voter_id}  setimagefunc={(e)=>setdata({...data,voter_id:e})}  removeimageuploadfunc = {()=>setdata({...data,voter_id:''})} />
                </div>

                <div className='mt-2'>
                <Uploader required={false} name="ration_card" label={'Ration Card'} image={data?.ration_card}  setimagefunc={(e)=>setdata({...data,ration_card:e})}  removeimageuploadfunc = {()=>setdata({...data,ration_card:''})} />
                </div>
                
                </div>
                </div>}

                {step === 2 &&  
                <div className='block md:flex '>
                <div className='w-[100%] md:w-[50%] mr-2'>
                   <div>
                   <TextInput 
                      mandatory={true}
                      label={'Registration No'}  
                      variant="standard"
                      name="site_no"
                      type="text"
                      value={data.site_no}
                      error={error.site_no}
                      handlechange={handlechange}
                      placeholder="Enter  name"
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div>  

                   <div>
                   <h6 className='text-[11px] font-[400] mb-1 mt-2' >{'Registration Date'}</h6>
                   <div className='border border-slate-300 border-l-4 border-l-slate-600'> 
                   <input type="date" placeholder='' format="YYYY-MM-DD" value={data?.registration_date} bordered={false}  className='w-[100%] p-[3px] py-[6.5px] focus:outline-none text-[11px]' onChange={(e)=>{setdata({...data,registration_date:e.target.value})}} />
                   </div> 
                   {error?.registration_date !== '' && error?.registration_date !== undefined &&
                  <div className='flex items-center mt-1'>
                  <BiErrorCircle className='text-red-500' size={14} />
                  <span className='text-[10px] text-red-500 ml-1'>{error?.registration_date}</span>
                  </div>}
                   </div> 

                   <div>
                    <TextInput 
                        mandatory={true}
                        label={'Survey No'}  
                        variant="standard"
                        name="survey_no"
                        type="text"
                        value={data.survey_no}
                        error={error.survey_no}
                        handlechange={handlechange}
                        InputLabelProps={{
                            style: { color: '#fff', }, 
                        }}/>
                   </div>   

                   <div>
                    <TextInput 
                        mandatory={true}
                        label={'Village'}  
                        variant="standard"
                        name="village"
                        type="text"
                        value={data.village}
                        error={error.village}
                        handlechange={handlechange}
                        InputLabelProps={{
                            style: { color: '#fff', }, 
                        }}/>
                   </div> 

                   <div>
                    <TextInput 
                        mandatory={true}
                        label={'Extent'}  
                        variant="standard"
                        name="extent"
                        type="text"
                        value={data.extent}
                        error={error.extent}
                        handlechange={handlechange}
                        InputLabelProps={{
                            style: { color: '#fff', }, 
                        }}/>
                   </div> 


                   <div>
                    <TextInput 
                        mandatory={true}
                        label={'Purchased From'}  
                        variant="standard"
                        name="purchased_from"
                        type="text"
                        value={data.purchased_from}
                        error={error.purchased_from}
                        handlechange={handlechange}
                        InputLabelProps={{
                            style: { color: '#fff', }, 
                        }}/>
                   </div> 


                   <div>
                   <h6 className='text-[11px] font-[400] mb-1 mt-2' >{'Date of Contact'}</h6>
                   <div className='border border-slate-300 border-l-4 border-l-slate-600'> 
                   <input type="date" placeholder='' format="YYYY-MM-DD" value={data?.date_of_contact} bordered={false}  className='w-[100%] p-[3px] py-[6.5px] focus:outline-none text-[11px]' onChange={(e)=>{setdata({...data,date_of_contact:e.target.value})}} />
                   </div> 
                   {error?.date_of_contact !== '' && error?.date_of_contact !== undefined &&
                  <div className='flex items-center mt-1'>
                  <BiErrorCircle className='text-red-500' size={14} />
                  <span className='text-[10px] text-red-500 ml-1'>{error?.date_of_contact}</span>
                  </div>}
                   </div> 

                   <div>

                   <h6 className='text-[11px] font-[400] mb-1 mt-2' >Category</h6>
                    <div className='border-[#d0d7de] focus:ring-0 outline-none border   w-full text-[400] text-[12px] border-l-4 border-l-slate-600'> 
                    <Select className='w-[100%]' bordered={false} value={data.category} options={category} onChange={(e)=>setdata({...data,category:e})} />
                    </div>
                    <ErrorComponent error={error.category} />
                   
                   </div>  

                   <div>
                   <TextAreaInput1 
                      mandatory={false}
                      label={'Site Info'}  
                      variant="standard"
                      name="site_info"
                      type="text"
                      value={data.site_info}
                      error={error.site_info}
                      handlechange={handlechange}
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div> 

                    



                   

                  
                   
                </div> 

                <div className='w-[100%] md:w-[50%] md:ml-2 mt-2'>


                <h6 className='text-[11px] font-[400] mb-1 mt-1' >{'Khata'}</h6>
                <div className='flex mb-2.5'>
                <div className='flex items-center mt-0 mr-2'>
                  {data?.katha  ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,katha:!data.katha})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,katha:!data.katha})}  /> }
                  <h6 className="text-[12px] font-[400] ml-2">Yes</h6> 
                </div> 

                <div className='flex items-center mt-0 mr-2'>
                  {!data?.katha  ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,katha:!data.katha})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,katha:!data.katha})}  /> }
                  <h6 className="text-[12px] font-[400] ml-2">No</h6> 
                </div> 
                </div>

                <Uploader name="muda_docs" required={false} label={'Muda Approved Docs'} image={data?.muda_docs}  setimagefunc={(e)=>setdata({...data,muda_docs:e})}  removeimageuploadfunc = {()=>setdata({...data,muda_docs:''})} />
                </div>
                </div>} 


                {step === 3 &&
                <div className='block  '>
                  {attachments?.length < 10 && <h6 className='bg-slate-100 px-2 py-1 w-[23px] mt-2 text-[11px] mb-2 flex justify-end' onClick={()=>setattachments([...attachments,{...attachment}])}>+</h6>}
                  <div className='mt-2 grid grid-cols-2'> 
                  {attachments?.map((a,i)=>(
                  <div className='mt-2 w-[96%] mr-2'>
                  <Uploader name="file" required={false} label={'File '} image={a?.file}  setimagefunc={(e)=>setfiles(e,i)}  removeimageuploadfunc = {()=>removeimage(i)} />
                  </div>
                  ))}
                  </div>

                </div>
                }






               
            </div> 
            <div className='mt-2 flex items-center justify-end'>
                  <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={goBack} />
                  <h6 className='w-2'></h6>
                  <ButtonFilledAutoWidth btnName={step !== 3 ? 'Next' : 'Save'} onClick={submitData}  />
                </div>
       </div>


      </div>
    </div>
  )
}

export default AllocationSiteCE