import React from 'react'
import {BiErrorCircle} from 'react-icons/bi'

function ErrorComponent(props) {
  return (
    <>
    {props.error !== '' && props.error !== undefined &&
    <div className='flex items-center mt-1'>
    <BiErrorCircle className='text-red-500' size={14} />
    <span className='text-[10px] text-red-500 ml-1'>{props.error}</span>
    </div>}
    </>
  )
}

export default ErrorComponent