import { IconButton, Tooltip } from '@mui/material'
import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
// import { CreateLeadsListBasedonIdService, DeleteLeadService, GetLeadNoteService, GetLeadReminderService, GetLeadsService, SearchLeadService } from '../../services/LeadServices';
import moment from 'moment';
import {AiOutlineEdit} from 'react-icons/ai';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { DatePicker } from 'antd';
import {RiBillLine} from 'react-icons/ri';
// import DailyTask from './dailyTask/DailyTask';
import {AiOutlineFilter,AiOutlinePlus,AiOutlineDelete} from 'react-icons/ai';
import { toast } from 'react-hot-toast';
// import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import AllocateMenu from './AllocateMenu';
import { DeleteLandAllocateService, FilterLandAllocateService, GetLandAllocateService } from '../../services/LandAllocateServices';

function AllocateSiteList() {

  const location = useLocation();
  const user = useSelector(state=>state.Auth)
  const navigate = useNavigate();


  const [data,setdata]  = useState([])
  const [type,settype] = useState('')
  const [page,setpage] = useState(1)
  const [filter_drawer,setfilter_drawer] = useState(false)


  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})

 
  
  useEffect(()=>{
    setpage(1)
  },[location.pathname])


  useEffect(()=>{
    if(search.activate){
      applyfilterfunction(page)
    }else{
      getdata(page)
    }
  },[page])


  
  async function getdata(page){
      setfilter_drawer(false)
      const response =  await GetLandAllocateService(page);
      setdata(response.data)
  }

  async function applyfilterfunction(page){
    setsearch({...search,activate:true})
    let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    const response = await FilterLandAllocateService({search_text:search.text,from_date:(search?.from_date !== ''  && search?.from_date !== null ) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
    setdata(response.data)
  }

  async function resetfunc(){
    setsearch({text:'',from_date:'',to_date:'',activate:false})
    getdata(1)
  }

  function returnfilename(v){
    let string = v?.split("/")[v?.split("/")?.length - 1]
    return string;
  }

  async function deleteData(v){
    const response = await DeleteLandAllocateService(v)
    if(response?.status == 200){
      toast.success(response?.data?.data)
      getdata(page)
    }
  }

  

  

  


  return (
    <div className='h-screen w-full  flex max-h-screen '>
  
     <AllocateMenu />
      <div className='px-3 md:px-4  pt-4 w-[100%]'>
      <div  className={`${data?.datas?.length === 0 ? 'w-[100%]' : 'w-[100%] md:w-[96%]'}`}>
        <div  className='w-[100%] '>
          <div className='block   md:flex items-center justify-between border-b pb-2'>
            <div className='flex justify-between items-center'>
              <h6 className='font-[500] text-[13px] '>Total Site Assigned {type} ({data?.pagination?.total})</h6>
              <div className='block md:hidden  items-center'>
              <div className='flex items-center text-[12px] -mr-2 md:mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>
              </div>
              </div>

              <div className='flex items-center md:justify-between mt-2 md:mt-0'>
              <div className='hidden md:block  items-center'>
              <div className='flex items-center text-[12px] -mr-2 md:mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>
              </div>
              <DatePicker size='small' style={{fontSize:'11px',minWidth:'90px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

              <DatePicker size='small' style={{fontSize:'11px',minWidth:'90px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

              <div className='mr-2'>
              <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className=' text-[12px] border py-[6.5px] focus:ring-0 focus:outline-0 w-[70px] md:w-28 px-2 rounded-md border-slate-300' />
              </div>      

              <div className='hidden md:block'>
              <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
              </div>
              <AiOutlineFilter onClick={()=>applyfilterfunction(1)} size={26} className='border-slate-300 bg-[#f6f8fa] rounded-[3px] px-1 p-1 text-[#000] block md:hidden' />
              <div className='ml-2 hidden md:block'>
              <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('/allocate/site_list/create')}/> 
              </div>
              <AiOutlinePlus onClick={()=>navigate('/allocate/site_list/create')} size={26}  className='bg-amber-400 rounded-[3px] px-1 ml-2 p-1 text-black block md:hidden' />
              </div>
           </div>
        
        {data?.datas?.length === 0 &&
          <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[400] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
        </div>
        }

        {data?.datas?.length > 0 &&
        <div className='max-h-[85vh] w-auto mt-4 border-t   border-l border-r overflow-y-scroll'>
        <div className='md:sticky top-0 z-50 flex  w-full bg-white py-[-5px]  border-slate-200 relative'>
        {/* <p className='w-[13px] h-[13px] rounded-[2px] border p-[1.6px] flex items-center justify-center' > </p>  */}
            <h6  className='top-0 bg-white z-50 text-[10px] w-[60px]  border-b  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'> SL NO </h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[130px]  md:w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Site No</h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[120px]  md:w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Name</h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[100px]  md:w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[120px]  md:w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[100px]  md:w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Address </h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[100px]  md:w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Site Info</h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[100px]  md:w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Site Stage</h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[100px]  md:w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created On</h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[100px]  md:w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created By</h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[100px]  md:w-[110px]  px-2 py-1 font-[600] text-slate-600 '>Actions</h6>
        </div>
      
        {data?.datas?.map((d,i)=>(
        <div  key={d?._id} className={`flex z-50 w-[100%] border-slate-200 z-0`}  >
          {/* <p className='w-[13px] h-[13px] rounded-[2px] border p-[1.6px] flex items-center justify-center' ></p> */}
            <h6 className='top-0 z-30 text-[12px] text-[10px]  w-[60px]  py-1 border-b font-[400] text-slate-600 cursor-pointer border-r pl-3'>{page === 1 ? (i+1) : ((i+1) * page * data?.pagination?.limit)}</h6>

            <h6 className='text-[11px] font-[400] min-w-[130px]  md:w-[130px] border-b px-2 py-1 truncate border-r  border-slate-200'>{d?.site_no}</h6>
            <h6 className='text-[11px] font-[400] mmin-w-[120px]  md:w-[130px] border-b px-2 py-1 truncate border-r  border-slate-200'>{d?.name}</h6>
            <h6 className='text-[11px] font-[400] min-w-[100px]  md:w-[100px] border-b px-2 py-1 truncate border-r  border-slate-200'>{d?.mobile}</h6>
            <h6 className='text-[11px] font-[400] min-w-[120px]  md:w-[130px] border-b px-2 py-1 truncate border-r  border-slate-200'>{d?.email}</h6>
            <h6 className='text-[11px] font-[400] min-w-[100px]  md:w-[150px] border-b px-2 py-1 truncate border-r  border-slate-200'>{d?.address}</h6>
            <h6 className='text-[11px] font-[400] min-w-[100px]  md:w-[100px] border-b px-2 py-1 truncate border-r  border-slate-200'>{d?.site_info}</h6>
            <h6 className='text-[11px] font-[400] min-w-[100px] w-[100px] border-b px-2 py-1 truncate border-r  border-slate-200'>{d?.stage?.name}</h6>
            <h6 className='text-[11px] font-[400] min-w-[100px] w-[100px] border-b px-2 py-1 truncate border-r  border-slate-200 '>{moment(d?.createdAt).format('ll')}</h6>
            <h6 className='text-[11px] font-[400] min-w-[100px] w-[100px] border-b px-2 py-1 truncate border-r  border-slate-200 '>{d?.created_by?.name}</h6>
            <h6  className='text-[11px] min-w-[100px] w-[110px] border-b px-2 py-1 flex'>
              <Tooltip title="Detail">
              <span><RiBillLine size={12} onClick={()=>navigate(`detail`,{state:d?._id})} className=" z-10"/></span>
              </Tooltip>

              <Tooltip title="Edit">
              <span><AiOutlineEdit size={12} className="z-10 ml-3" onClick={()=>navigate('edit',{state:d?._id})}/></span>
              </Tooltip>

              {/* <Tooltip title="Preview">
              <span><VscOpenPreview size={13} className="z-10 ml-2" onClick={()=>{setselected_lead(d);setpreview(true)}}/></span>
              </Tooltip> */}
              {user?.roles?.includes('hod') &&
              <Tooltip title="Delete">
              <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{deleteData(d?._id)}}/></span>
              </Tooltip>}
              

            
            </h6>
        </div>))}
        </div>}
        </div>

        
      


      </div>
      </div>
    </div>
  )
}

export default AllocateSiteList