import React, { useEffect,useState } from 'react'
import AllocateMenu from './AllocateMenu'
import GoBack from '../../components/back/GoBack';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import moment from 'moment';
import {GrLocationPin} from 'react-icons/gr';
import {AiOutlineMail,AiOutlinePhone,AiOutlineEdit,AiOutlineDownload} from 'react-icons/ai';
import { GetLandAllocateServiceDetail } from '../../services/LandAllocateServices';
import axios from 'axios';
import fileDownload from "js-file-download";
import { Modal } from 'antd';
import { TextInput } from '../../components/input';
import Uploader from '../../components/Uploader';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { CreateLandAllocateAttachmentService, GetLandAllocateAttachmentService } from '../../services/LandAllocateAttachmentServices';
import ErrorComponent  from '../../components/ErrorComponent';


function AllocateDetail() { 
 
  const {state} = useLocation()
  const navigate = useNavigate()
  const [data,setdata] = useState({})
  const [step,setstep] = useState(1)
  const [modal,setmodal] = useState(false)

  const [attachment,setattachment] = useState({name:'',file:''})
  const [attachments,setattachments] = useState([])
  const [attachment_error,setattachment_error] = useState({name:'',file:''})

  useEffect(()=>{
    getData()
    getAttachmentData()
  },[])

  async function getData(){
    const response = await GetLandAllocateServiceDetail(state)
    let d = response?.data?.datas[0]
    setdata(d)
  }

  async function getAttachmentData(){
    const response = await GetLandAllocateAttachmentService(state)
    let d = response?.data?.data
    setattachments(d)
  }

  function returnfilename(v){
    let string = v?.split("/")[v?.split("/")?.length - 1]
    return string;
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            toast.success("Attachment Downloaded") 
        })
    }
  
 function handlechange(e){
    setattachment({...attachment,[e.target.name] : e.target.value})
    setattachment_error({...attachment_error,[e.target.name] : ''})
  }

  async function submitData(){
    if(!attachment.name){
        setattachment_error({...attachment_error,name:'This Field is required*'})
    }else if(!attachment.file){
        setattachment_error({...attachment_error,file:'This Field is required*'})
    }else{
        const fd = new FormData()
        fd.append('name',attachment?.name)
        fd.append('file',attachment?.file)
        fd.append('land_allocated',data._id)
        const response = await CreateLandAllocateAttachmentService(fd)
        if(response.status === 200){
            toast.success("File Uploaded Successfully")
            setattachment({name:'',file:''})
            setattachment_error({name:'',file:''})
            getAttachmentData()
            setmodal(false)
        }
    }
  }

  console.log("attachments?.length",attachments?.length)


  return (
    <div className='relative w-full min-h-screen h-screen overflow-hidden max-h-screen'>

        <Modal  open={modal} closable={false} width={300} footer={false}>
            <div className='relative'>

                <h6 >Add New Attachment</h6>
                <h6 className='leading-tight text-[10.5px] opacity-75 my-2 '>Use the below form to add additional attachment of the site allocated.</h6>
                <TextInput 
                      mandatory={true}
                      label={'File Name'}  
                      variant="standard"
                      name="name"
                      type="text"
                      value={attachment.name}
                      error={attachment_error.name}
                      handlechange={handlechange}
                      placeholder="Enter name"
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>

                
                <div className='mt-2'>
                <Uploader name="file" required={true} label={'File / Docs'} image={attachment?.file}  setimagefunc={(e)=>{setattachment({...attachment,file:e});setattachment_error({...attachment_error,file:''})}}  removeimageuploadfunc = {()=>{setattachment({...attachment,file:''});setattachment_error({...attachment_error,file:''})}} />
                </div>
                 
                 <ErrorComponent error={attachment_error?.file} />


                <div className='mt-3 flex items-center justify-end'>
                  <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setmodal(false)} />
                  <h6 className='w-2'></h6>
                  <ButtonFilledAutoWidth btnName={'Upload'} onClick={submitData}  />
                </div>


                
            </div>

        </Modal>
        <div className='block md:flex pt-2 md:pt-0'>
              <AllocateMenu />
                <div className='px-4 block md:flex w-[100%] md:w-[88%]'>
                    <div className='w-full border-b pb-4 border-slate-100 md:w-[25%] relative md:min-h-screen  md:max-h-screen '>

                        <GoBack />
                        <div className='grid place-items-center pt-4 -ml-4'>
                        {/* <  */}
                        <span onClick={()=>navigate(`/allocate/site_list/edit`,{state:data?._id})} className=' absolute top-1 right-0 text-slate-600 cursor-pointer font-[600] md:mr-2 px-1 py-1 text-[10px] flex items-center'><AiOutlineEdit size={13} className='mr-1.5'/>  {'Edit'}</span>
                        
                       <div className='flex px-4 w-full'>
                          <img src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.photo}`}  className='w-24 h-24 object-cover bg-slate-100'  />
                          <div className='ml-2'>
                            <h6 className='text-[13px] mb-1'>{data?.name}</h6>
                            <h6 className='text-[10px] mb-1 font-[800]'>{data?.mobile}</h6>
                            <h6 className='text-[10px] text-gray-500'>{data?.address}</h6>
                            <div className='grid grid-cols-3 place-items-start -ml-2 w-[100%] mt-2'>

                                <CopyToClipboard text={data?.email} onCopy={() => toast.success('Copied to clipboard')}>
                                <div className='grid place-items-center cursor-pointer'>
                                <AiOutlineMail size={24} className='border  mx-1.5  p-[6px] text-gray-700' />
                                </div>
                                </CopyToClipboard>

                                <CopyToClipboard text={data?.phone} onCopy={() => toast.success('Copied to clipboard')}>
                                <div className='grid place-items-center cursor-pointer'>
                                <AiOutlinePhone size={24} className='border  mx-1.5  p-[6px] text-gray-700' />
                                </div>
                                </CopyToClipboard>

                                <CopyToClipboard text={data?.industry?.name} onCopy={() => toast.success('Copied to clipboard')}>
                                <div className='grid place-items-center cursor-pointer'> 
                                <GrLocationPin size={24} className='border  mx-1.5  p-[6px] text-gray-700' />
                                </div>
                                </CopyToClipboard>

                      

                        </div>
                          </div>
                       </div>


                        </div>

                        

                        <div className='mt-2 w-[90%] ml-[3%]'>
                        </div>


                        <h6 className='text-center text-[9px]  mt-2  '>Last Activity : <span className='font-[600]'> {data?.updatedAt?._d !== undefined ? moment(data?.updatedAt?._d)?.format('lll') :  moment(data?.updatedAt)?.format('lll')}</span></h6>

                        <div className='flex items-center mr-5 mt-5 border-t border-slate-100 border-b'>
                        <h6 onClick={()=>setstep(1)} className={`text-[10px] ${step === 1 && 'bg-amber-400 '} cursor-pointer w-full py-2 text-center uppercase  pb-2 font-[400]`}>Customer Info</h6>
                        <h6 onClick={()=>setstep(2)} className={`text-[10px] ${step === 2 && 'bg-amber-400 '} cursor-pointer w-full py-2 text-center uppercase  pb-2 font-[400]`}>Site Info</h6>
                        </div>
                        
                        {step === 1 &&
                        <div className='mt-5'>
                        {data?.name &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 mb-1 text-gray-400'>Name</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.name}</h6>
                        </>}
                        {data?.mobile &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 mb-1 text-gray-400'>Mobile</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.mobile}</h6>
                        </>}
                        {data?.email &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 mb-1 text-gray-400'>Email</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.email}</h6>
                        </>}
                        {data?.address &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 mb-1 text-gray-400'>Address</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.address}</h6>
                        </>}

                        {data?.secondary_name &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 mb-1 text-gray-400'>Secondary Name</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.secondary_name}</h6>
                        </>}

                        {data?.secondary_contact &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 mb-1 text-gray-400'>Secondary Contact</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.secondary_contact}</h6>
                        </>}

                        {data?.comments &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 mb-1 text-gray-400'>Comments</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.comments}</h6>
                        </>}

                        {data?.created_by &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 mb-1 text-gray-400'>Created By</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.created_by?.name}</h6>
                        </>}
                       
                        </div>}

                        {step === 2 &&
                        <div className='mt-5'>
                        {data?.site_no &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 mb-1 text-gray-400'>Site No</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.site_no}</h6>
                        </>}
                        {data?.site_info &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 text-gray-400'>Site Info</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.site_info}</h6>
                        </>}

                        {data?.registration_date &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 text-gray-400'>Registration Date</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{moment(data?.registration_date)?.format('LL')}</h6>
                        </>}


                        {data?.village &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 text-gray-400'>Village</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.village}</h6>
                        </>}


                        {data?.extent &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 text-gray-400'>Extent</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.extent}</h6>
                        </>}

                        {data?.survey_no &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 text-gray-400'>Survey No</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.survey_no}</h6>
                        </>}

                        {data?.category &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 text-gray-400'>Category </h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.category}</h6>
                        </>}
                        {data?.khata &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 text-gray-400'>Khata </h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{'YES'}</h6>
                        </>}

                        {data?.purchased_from &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 text-gray-400'>Purchased From</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{data?.purchased_from}</h6>
                        </>}

                        {data?.date_of_contact &&
                        <>
                        <h6 className='text-[10px] mb-0 mt-2 text-gray-400'>Date of Contact</h6>
                        <h6 className='text-[11px] mb-0 font-[500] '>{moment(data?.date_of_contact)?.format('LL')}</h6>
                        </>}
                        
                        </div>}
                    </div>
                    <div className='md:w-[76%] mt-4  md:mt-0 md:p-4 md:bg-gray-50  relative min-h-screen max-h-screen '>
                        <h6 className='text-[13px] font-[600] opacity-75'>DOCS SUBMITED</h6>
                        <h6 onClick={()=>setmodal(true)} className='text-[10px] underline absolute right-2 top-4 cursor-pointer font-[500] opacity-75'>ADD DOCS</h6>

                        <div className='grid grid-cols-2 md:grid-cols-4 mt-2'>

                        {(data?.photo !== '' && data?.photo !== undefined) &&
                            <div className='bg-white p-2 overflow-hidden w-[98%] mr-[2%] border border-gray-100'>
                                <div className='flex relative py-1 '>
                                    <div className='absolute -top-2  -right-2'>
                                        <span className='text-[7px] bg-slate-200 text-[#000] flex items-center  mb-0 pb-0 border-l border-b border-slate-100 uppercase flex pl-2'>Photo <AiOutlineDownload size={18} onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.photo}`,returnfilename(data?.photo))} className='ml-2 border-l border-slate-100 pl-2 mr-2 ' /></span>
                                    </div>
                                    <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 mt-1 object-contain' />
                                    <div className='ml-2 mt-0.5'>
                                    <h6 className='font-[400] mt-1 text-[10px]'>{returnfilename(data?.photo)}</h6>
                                    </div>
                                </div>    
                            </div>}   

                            {(data?.aadhar_file !== '' && data?.aadhar_file !== undefined) &&
                             <div className='bg-white p-2 overflow-hidden w-[98%] mr-[2%] border border-gray-100'>
                                <div className='flex relative py-1 '>
                                    <div className='absolute -top-2 -right-2'>
                                        <span className='text-[7px] flex items-center bg-slate-200 text-[#000] mb-0 pb-0 border-l border-b border-slate-100 uppercase flex pl-2'>Aadhar <AiOutlineDownload onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.aadhar_file}`,returnfilename(data?.aadhar_file))} size={18} className='ml-2 border-l border-slate-100 pl-2 mr-2 ' /></span>
                                    </div>
                                    <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 mt-1 object-contain' />
                                    <div className='ml-2 mt-0.5'>
                                    <h6 className='font-[400] mt-1 text-[10px]'>{returnfilename(data?.aadhar_file)}</h6>
                                    </div>
                                </div>    
                            </div>}  

                            {(data?.pan_file !== '' && data?.pan_file !== undefined) &&
                            <div className='bg-white p-2 overflow-hidden w-[98%] mr-[2%] border border-gray-100'>
                                <div className='flex relative py-1 '>
                                    <div className='absolute -top-2 -right-2'>
                                        <span className='text-[7px] flex items-center bg-slate-200 text-[#000] mb-0 pb-0 border-l border-b border-slate-100 uppercase flex pl-2'>Pan <AiOutlineDownload onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.pan_file}`,returnfilename(data?.pan_file))} size={18} className='ml-2 border-l border-slate-100 pl-2 mr-2 ' /></span>
                                    </div>
                                    <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 mt-1 object-contain' />
                                    <div className='ml-2 mt-0.5'>
                                    <h6 className='font-[400] mt-1 text-[10px]'>{returnfilename(data?.pan_file)}</h6>
                                    </div>
                                </div>    
                            </div>}  

                            {(data?.dl_file !== '' && data?.dl_file !== undefined) &&
                            <div className='bg-white p-2 overflow-hidden w-[98%] mr-[2%] border border-gray-100'>
                                <div className='flex relative py-1 '>
                                    <div className='absolute -top-2 -right-2'>
                                        <span className='text-[7px] flex items-center bg-slate-200 text-[#000] mb-0 pb-0 border-l border-b border-slate-100 uppercase flex pl-2'>dl <AiOutlineDownload onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.dl_file}`,returnfilename(data?.dl_file))} size={18} className='ml-2 border-l border-slate-100 pl-2 mr-2 ' /></span>
                                    </div>
                                    <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 mt-1 object-contain' />
                                    <div className='ml-2 mt-0.5'>
                                    <h6 className='font-[400] mt-1 text-[10px]'>{returnfilename(data?.dl_file)}</h6>
                                    </div>
                                </div>    
                            </div>}    

                            {(data?.muda_docs !== '' && data?.muda_docs !== undefined) &&
                             <div className='bg-white p-2 overflow-hidden w-[98%] mr-[2%] mt-[3px] border border-gray-100'>
                                <div className='flex relative py-1 '>
                                    <div className='absolute -top-2 -right-2'>
                                        <span className='text-[7px] flex items-center bg-slate-200 text-[#000] mb-0 pb-0 border-l border-b border-slate-100 uppercase flex pl-2'>Muda <AiOutlineDownload onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.muda_docs}`,returnfilename(data?.muda_docs))} size={18} className='ml-2 border-l border-slate-100 pl-2 mr-2 ' /></span>
                                    </div>
                                    <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 mt-1 object-contain' />
                                    <div className='ml-2 mt-0.5'>
                                    <h6 className='font-[400] mt-1 text-[10px]'>{returnfilename(data?.muda_docs)}</h6>
                                    </div>
                                </div>    
                            </div>} 


                            {(data?.sales_deed !== '' && data?.sales_deed !== undefined) &&
                            <div className='bg-white p-2 overflow-hidden w-[98%] mr-[2%] mt-[3px] border border-gray-100'>
                                <div className='flex relative py-1 '>
                                    <div className='absolute -top-2 -right-2'>
                                        <span className='text-[7px] flex items-center bg-slate-200 text-[#000] mb-0 pb-0 border-l border-b border-slate-100 uppercase flex pl-2'>Sales Deed <AiOutlineDownload onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.sales_deed}`,returnfilename(data?.sales_deed))} size={18} className='ml-2 border-l border-slate-100 pl-2 mr-2 ' /></span>
                                    </div>
                                    <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 mt-1 object-contain' />
                                    <div className='ml-2 mt-0.5'>
                                    <h6 className='font-[400] mt-1 text-[10px]'>{returnfilename(data?.sales_deed)}</h6>
                                    </div>
                                </div>    
                            </div>}     

                            {(data?.noc !== '' && data?.noc !== undefined) &&
                            <div className='bg-white p-2 overflow-hidden w-[98%] mr-[2%] mt-[3px] border border-gray-100'>
                                <div className='flex relative py-1 '>
                                    <div className='absolute -top-2 -right-2'>
                                        <span className='text-[7px] flex items-center bg-slate-200 text-[#000] mb-0 pb-0 border-l border-b border-slate-100 uppercase flex pl-2'>NOC <AiOutlineDownload onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.noc}`,returnfilename(data?.noc))} size={18} className='ml-2 border-l border-slate-100 pl-2 mr-2 ' /></span>
                                    </div>
                                    <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 mt-1 object-contain' />
                                    <div className='ml-2 mt-0.5'>
                                    <h6 className='font-[400] mt-1 text-[10px]'>{returnfilename(data?.noc)}</h6>
                                    </div>
                                </div>    
                            </div>}   

                            {(data?.voter_id !== '' && data?.voter_id !== undefined) &&
                            <div className='bg-white p-2 overflow-hidden w-[98%] mr-[2%] mt-[3px] border border-gray-100'>
                                <div className='flex relative py-1 '>
                                    <div className='absolute -top-2 -right-2'>
                                        <span className='text-[7px] flex items-center bg-slate-200 text-[#000] mb-0 pb-0 border-l border-b border-slate-100 uppercase flex pl-2'>VOTER ID <AiOutlineDownload onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.voter_id}`,returnfilename(data?.voter_id))} size={18} className='ml-2 border-l border-slate-100 pl-2 mr-2 ' /></span>
                                    </div>
                                    <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 mt-1 object-contain' />
                                    <div className='ml-2 mt-0.5'>
                                    <h6 className='font-[400] mt-1 text-[10px]'>{returnfilename(data?.voter_id)}</h6>
                                    </div>
                                </div>    
                            </div>}   


                            {(data?.ration_card !== '' && data?.ration_card !== undefined) &&
                            <div className='bg-white p-2 overflow-hidden w-[98%] mr-[2%] mt-[3px] border border-gray-100'>
                                <div className='flex relative py-1 '>
                                    <div className='absolute -top-2 -right-2'>
                                        <span className='text-[7px] flex items-center bg-slate-200 text-[#000] mb-0 pb-0 border-l border-b border-slate-100 uppercase flex pl-2'>RATION CARD <AiOutlineDownload onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.ration_card}`,returnfilename(data?.ration_card))} size={18} className='ml-2 border-l border-slate-100 pl-2 mr-2 ' /></span>
                                    </div>
                                    <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 mt-1 object-contain' />
                                    <div className='ml-2 mt-0.5'>
                                    <h6 className='font-[400] mt-1 text-[10px]'>{returnfilename(data?.ration_card)}</h6>
                                    </div>
                                </div>    
                            </div>}    

                           
                            {attachments?.map((a)=>(
                             <div className='bg-white p-2 overflow-hidden w-[98%] mr-[2%] mt-[3px] border border-gray-100'>
                                <div className='flex relative py-1 '>
                                    <div className='absolute -top-2 -right-2'>
                                        <span className='text-[7px] flex items-center bg-slate-200 text-[#000] mb-0 pb-0 border-l border-b border-slate-100 uppercase flex pl-2 uppercase'>{a?.name} <AiOutlineDownload onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${a?.file}`,returnfilename(a?.file))} size={18} className='ml-2 border-l border-slate-100 pl-2 mr-2 ' /></span>
                                    </div>
                                    <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 mt-1 object-contain' />
                                    <div className='ml-2 mt-0.5'>
                                    <h6 className='font-[400] mt-1 text-[10px] truncate'>{returnfilename(a?.file)}</h6>
                                    </div>
                                </div>    
                            </div>))}  

                        </div>
                    </div>
                </div>    
        </div>
    </div>
  )
}

export default AllocateDetail