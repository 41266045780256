import React,{useState} from 'react'
import ProfileMenu from './ProfileMenu'
import {WiDaySunny} from 'react-icons/wi'
import {BsMoon} from 'react-icons/bs'

function ThemeUser() {

  const [step,setstep] = useState(1)
  

  return (
    <div className='px-2 mx-0 box-border  max-h-screen overflow-y-scroll overflow-x-hidden'>
       <div className='block sm:flex'>
        <ProfileMenu />
        <div className='ml-0 w-[94%] md:w-full ml-4 md:mt-4 md:pt-5'>
        <h6 className='border-b mt-1 pb-2 mb-4 font-[700] text-[17px] border-[#d0d7de]'>Theme preferences</h6>


<div >    
    <div className='mr-0 w-[98%] md:w-3/5 mr-16'>
    <div className='block'>

    <div className='mr-0 sm:mr-4'>
       
        <h6 className='text-[11.5px] font-[400] text-gray-600 pb-0 leading' >Choose how Fidelitus App looks to you. Select a single theme, or sync with your system and automatically switch between day and night themes.</h6> 

        
        <h6 className='font-[800] text-[14px] py-2'>Theme mode</h6>


        <div className='md:flex'>
          
          <div className='mr-2 overflow-hidden'>
              <div onClick={()=>setstep(1)}  className={`cursor-pointer border ${step === 1 && 'border-[#a8d8ff]'}  rounded rounded-md overflow-hidden`}>
                <div className={`flex  items-center border-b  justify-between ${step === 1 ? 'bg-[#ddf4ff] border-[#a8d8ff]' : 'bg-[#f6f8fa]'}  p-2`}>
                  <span className='flex items-center'>
                  <WiDaySunny />
                  <span className='text-[12px] font-[400] ml-1'> Day theme</span> 
                  </span> 
                  <span className='text-[#0a69da] font-[500] text-[11px] px-1 rounded-md border border-[#0a69da]' style={{padding:'2px'}}>Active</span>
                  </div>

                  <h6 className='text-[11px] leading-normal px-2 pt-3 text-gray-600 opacity-80'>This theme will be active when your system is set to “light mode”</h6>

                  <div className='border m-2 rounded-md  h-[120px] md:h-auto overflow-hidden'>
                    <img src="https://fidecrmfiles.s3.amazonaws.com/light_preview.svg" className='object-stretch  w-full' alt="light_preview" />
                    <h6 className='border-t p-1 pl-3  font-[700] text-[13px] py-2'>Light default</h6>
                  </div>
                  
               </div>

               
          </div>

          <div className='mt-4 md:mt-0 md:ml-2 overflow-hidden'>
              <div onClick={()=>setstep(2)} className={`cursor-pointer border ${step === 2 && 'border-[#a8d8ff]'}  rounded rounded-md overflow-hidden`}>
                <div className={`flex  items-center border-b  justify-between ${step === 2 ? 'bg-[#ddf4ff] border-[#a8d8ff]' : 'bg-[#f6f8fa]'}  p-2`}>
                  <span className='flex items-center'>
                  <BsMoon size={14} />
                  <span className='text-[12px] font-[400] ml-1'> Night theme</span> 
                  </span> 
                  <span className='text-[#0a69da] font-[500] text-[11px] px-1  rounded-md border border-[#0a69da]' style={{padding:'2px'}}>Active</span>
                  </div>

                  <h6 className='text-[11px] leading-normal px-2 pt-3 text-gray-600 opacity-80'>This theme will be active when your system is set to "dark mode”</h6>

                  <div className='border m-2 rounded-md h-[120px] md:h-auto overflow-hidden'>
                    <img src="https://fidecrmfiles.s3.amazonaws.com/dark_preview.svg" className='object-stretch w-full' alt="light_preview" />
                    <h6 className='border-t p-1 pl-3 font-[700] text-[13px] py-2'>Dark default</h6>
                  </div>
                  
               </div>


          </div>

        </div>

       



        {/* <span className='text-[11.5px] font-[400] text-[#646d76] pb-0 leading-none' >Looking to manage account security settings? You can find them in the <span className='text-[#0a69da] cursor-pointer hover:underline' onClick={()=>navigate('/settings/security')}>Password and authentication</span> page.</span>  */}


        </div> 
        </div>
        </div>

        </div>
        </div>    

    </div>    
    </div>
  )
}

export default ThemeUser