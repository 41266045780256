import React,{useState,useEffect} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import {TextInput} from '../../components/input';
// import Select from 'react-select'
import {BiErrorCircle,BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { CreateOnboardService, GetOnboardDetailService, UpdateOnboardService, UpdateRoleService } from '../../services/OnboardServices';
import { GetDepartmentService } from '../../services/DepartmentServices';
import { GetDesignationService } from '../../services/DesignationService';
// import { GetTaluk } from '../../services/CSDTServices';
import { toast } from 'react-hot-toast';
import { GetRoleService } from '../../services/RoleServices';
import SettingsMenu from '../staticscreens/SettingsMenu';
import GoBack from '../../components/back/GoBack';
import {Select} from 'antd'

function Onboard() {

  const navigate = useNavigate()  
  const location = useLocation()
  const {state} = useLocation()

  const [data,setdata] = useState({
    name:'',
    employee_id:'',
    department:'',
    designation_id:'',
    official_phone:'',
    official_email:'',
    dob:'',
    doj:'',
    doe:'',
    email:'',
    phone:'',
    roles:[],
    is_active:false,
    branch:'',
    is_verified:false,
    is_partially_verified:false,
    sent_for_review:false,
    app_access:false,
    crendtials_shared:false
  })

  useEffect(()=>{
    if(location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit'){
      getdata()
    }
    getoptions()
  },[])

  const [departmentArr,setdepartmentArr] = useState([])
  const [designationArr,setdesignationArr] = useState([])
  const [branchArr,setbranchArr] = useState([])
  const [rolesArr,setrolesArr] = useState([])

  const [error,seterror] = useState({
    name:'',
    employee_id:'',
    department:'',
    designation_id:'',
    official_phone:'',
    official_email:'',
    dob:'',
    doj:'',
    doe:'',
    email:'',
    phone:'',
    is_active:false,
    branch:'',
    is_verified:false,
    is_partially_verified:false,
    sent_for_review:false,
    app_access:false,
    crendtials_shared:false
  })

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function getoptions(){
    const response = await GetDepartmentService()
    const response1 = await GetDesignationService()
    const response3 = await GetRoleService()
    

    let arr = []
    let arr1 = []
    let arr2 = []
    let arr3 = []

    response?.data?.datas?.forEach((d)=>{
        arr.push({value:d?.id,label:d?.department_name})
    })
    response1?.data?.datas?.forEach((d)=>{
        arr1.push({value:d?.id,label:d?.designation_name})
    })
    response3?.data?.datas?.forEach((d)=>{
      arr3.push({value:d?.id,label:d?.display_name})
    })

    setdepartmentArr(arr)
    setdesignationArr(arr1)
    // setbranchArr(arr2)
    setrolesArr(arr3)
  console.log("rolesArr",arr3)

  }


  async function getdata(){
    const response = await GetOnboardDetailService(state)
    let d = response?.data?.datas[0]
    let roles = []
    d?.user_roles?.forEach((r)=>{
      roles.push({label:r?.roles?.display_name,value:r?.roles?._id})
    })
    setdata({...data,
      name:d?.name,
    employee_id:d?.employee_id,
    department:{value:d?.department_id[0]?._id,label:d?.department_id[0]?.department_name},
    designation_id:{value:d?.designation_id[0]?._id,label:d?.designation_id[0]?.designation_name},
    official_phone:d?.official_phone,
    official_email:d?.official_email,
    dob:d?.dob,
    doj:d?.doj,
    doe:'',
    roles:roles,
    email:d?.email,
    phone:d?.phone,
    is_active:d?.is_active,
    branch:{value:d?.branch?._id,label:d?.branch?.branch_name},
    is_verified:d?.is_verified,
    is_partially_verified:d?.is_partially_verified,
    sent_for_review:d?.sent_for_review,
    app_access:d?.app_access,
    crendtials_shared:d?.crendtials_shared
    })
  }

  async function handleSelect(e,e1){
    if(e1 === 'department'){
     setdata({...data,department:departmentArr?.find((d)=>d.value === e)})
     seterror({...error,department:''})
    }else  if(e1 === 'designation_id'){
     setdata({...data,designation_id:designationArr?.find((d)=>d.value === e)})
     seterror({...error,designation_id:''})
    }else  if(e1 === 'branch'){
     setdata({...data,branch:branchArr?.find((d)=>d.value === e)})
     seterror({...error,branch:''})
    }else if(e1 === 'roles'){
      let arr = []
      e?.forEach((ele)=>{
        arr?.push(rolesArr?.find((r)=>r?.value === ele))
      })
     setdata({...data,roles:arr})
     seterror({...error,roles:''})
    }
  }

  // console.log("dataa roles",data?.roles)
  

  async function submitdata(){
    if(!data.name){
        seterror({...error,name:'This Field is required*'})
    }else if(!data.employee_id){
        seterror({...error,employee_id:'This Field is required*'})
    }else if(!data.phone){
        seterror({...error,phone:'This Field is required*'})
    }else if(data.phone.length !== 10){
        seterror({...error,phone:'Enter a valid 10 digit mobile no*'})
    }else if(!data.dob){
        seterror({...error,dob:'This Field is required*'})
    }else if(!data.doj){
        seterror({...error,doj:'This Field is required*'})
    }else if(!data.email){
        seterror({...error,email:'This Field is required*'})
    }else if(!data.department){
        seterror({...error,department:'This Field is required*'})
    }else if(!data.designation_id){
        seterror({...error,designation_id:'This Field is required*'})
    }else if(!data.official_email){
        seterror({...error,official_email:'This Field is required*'})
    }else if(!data.official_phone){
        seterror({...error,official_phone:'This Field is required*'})
    }else if(data.official_phone.length !== 10){
        seterror({...error,official_phone:'This Field is required*'})
    }else{

        
        let send_data = {...data}
        send_data['designation_id'] = data?.designation_id?.value
        send_data['department_id'] = data?.department?.value
        send_data['branch'] = data?.branch?.value
        console.log("send_data",send_data)
        
        if(location.pathname.split('/')[location.pathname.split('/').length - 1] === 'create'){
            const response = await CreateOnboardService(send_data)
            if(response.status === 422){
                seterror({...error,...response?.data?.errors})
            }
            if(response.status === 201){
                toast.success("Onboarded Successfully")
                // resetform()
            }
        }else{
            const response = await UpdateOnboardService(send_data,state)
            if(response.status === 422){
                seterror({...error,...response?.data?.errors})
            }
            if(response.status === 200){
                toast.success("Onboarded Updated Successfully")
                // resetform()
                navigate(-1)
            }
        }
        
    }
  }

  async function updateRoles(){
    let arr = []
    data?.roles?.forEach((r)=>{
      arr?.push(r?.value)
    })
    const response = await UpdateRoleService({roles:arr,user_id:state})
    if(response.status == 200){
      toast.success("Role Assigned Successfully")
      getdata()
    }
  }

  function resetform(){
    setdata({
        name:'',
        employee_id:'',
        department:'',
        designation_id:'',
        official_phone:'',
        official_email:'',
        dob:'',
        doj:'',
        doe:'',
        email:'',
        phone:'',
        is_active:false,
        branch:'',
        is_verified:false,
        is_partially_verified:false,
        sent_for_review:false,
        app_access:false,
        crendtials_shared:false
      })
    seterror({
        name:'',
        employee_id:'',
        department:'',
        designation_id:'',
        official_phone:'',
        official_email:'',
        dob:'',
        doj:'',
        doe:'',
        email:'',
        phone:'',
        is_active:false,
        branch:'',
        is_verified:false,
        is_partially_verified:false,
        sent_for_review:false,
        app_access:false,
        crendtials_shared:false
      })
  }



  console.log("data roles",data?.roles)

  
  return (
    <div className='md:h-screen md:max-h-screen md:min-h-screen md:overflow-hidden'>
       {/* <div className='sm:block md:hidden'>
       <MobileBackOption goback={()=>navigate(-1)} name="HR FORM" />
       </div> */}

        <div className='md:flex'> 
         <SettingsMenu />
          <div className='px-5 w-full'>

          
        <div className='md:flex w-full md:min-h-screen md:max-h-screen md:overflow-y-scroll mb-10'>
        <div className='w-full md:border-r md:w-64 md:pr-5 md:pt-5'>
        <GoBack />  

        <h6 className='font-[700] mb-1'>Create/Edit Onboard Employee</h6>
          <h6 className='text-[11px] p-2  mb-2 bg-slate-100'>Use the below form to create or update a onboard employee</h6>
        
        <TextInput 
         label={'Name'}  
         variant="standard"
         name="name"
         type="text"
         error={error.name}
         value={data.name}
         handlechange={handlechange}
         placeholder="Enter your Department name"
         />

        <TextInput 
         label={'Employee Id'}  
         variant="standard"
         name="employee_id"
         type="text"
         error={error.employee_id}
         value={data.employee_id}
         handlechange={handlechange}
         placeholder="Enter your Employee Id"
         />

        <div className='flex justify-between'>

        <div className="w-full mr-1"> 
        <TextInput 
         label={'Phone'}  
         variant="standard"
         name="phone"
         type="text"
         error={error.phone}
         value={data.phone}
         handlechange={handlechange}
         placeholder="Enter your Phone"
         />
        </div> 

        <div className="w-full ml-1 mt-0.5">     
        <h6 className='text-[12px] font-[400] mb-1 mt-1'>Date of Birth </h6>
        <input className='border p-1.5 w-full  text-[11px] border-gray-300 ' type='Date' value={data?.dob?.slice(0,10)} onChange={(e)=>{setdata({...data,dob:e.target.value});seterror({...error,dob:''})}} />

         {/* <DatePicker ampm={false} placeholder='' style={{maxHeight:'40px',borderWidth:'2px solid #ddd',borderRadius:'4px',minWidth:'100%'}} inputFormat="MM/dd/yyyy" value={data?.dob === '' ? null : moment(data?.dob)} onChange={(v) => {setdata({...data,dob:v});seterror({...error,dob:''})}} /> */}
        {(error?.dob !== '' || error?.dob !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.dob}</h6>}
         </div>
         </div>

         <div className='flex justify-between mt-1 w-full'>
       
         <div className="w-full mr-1">
        <h6 className='text-[12px] font-[400] mb-1 mt-1'>Date of Joining </h6>
        <input className='border p-1.5 text-[11px] w-full  text-[11px] border-gray-300 ' type='Date' value={data?.doj?.slice(0,10)} onChange={(e)=>{setdata({...data,doj:e.target.value});seterror({...error,doj:''})}} />
          {/* <DatePicker placeholder='' style={{maxHeight:'40px',borderWidth:'2px solid #ddd',borderRadius:'4px',minWidth:'100%'}} inputFormat="MM/dd/yyyy" value={data?.doj === '' ? null : moment(data?.doj)} onChange={(v) => {setdata({...data,doj:v});seterror({...error,doj:''})}} /> */}
          {(error?.doj !== '' || error?.doj !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.doj}</h6>}
         </div>



         <div className="w-full ml-1">
        <h6 className='text-[12px] font-[400] mb-1 mt-1'>Date of Exit </h6>
        <input className='border p-1.5 text-[11px] w-full  text-[11px] border-gray-300 ' type='Date' value={data?.doe?.slice(0,10)} onChange={(e)=>{setdata({...data,doe:e.target.value});seterror({...error,doe:''})}} />
          {/* <DatePicker placeholder='' style={{maxHeight:'40px',borderWidth:'2px solid #ddd',borderRadius:'4px',minWidth:'100%'}} inputFormat="MM/dd/yyyy" value={data?.doe === '' ? null : moment(data.doe)} onChange={(v) => {setdata({...data,doe:v});seterror({...error,doe:''})}} /> */}
          {(error?.doe !== '' || error?.doe !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.doe}</h6>}
         </div>
         </div>


        <TextInput 
         label={'Email'}  
         variant="standard"
         name="email"
         type="text"
         error={error.email}
         value={data.email}
         handlechange={handlechange}
         placeholder="Enter your email address"
         />

       


        <h6 className='text-[12px] font-[400] mb-1 mt-1'>Department </h6>
         <Select
           value={data.department} 
           error={error.department}
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'department')} 
           options={departmentArr} 
           className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
           />
         {error.department !== '' && error.department !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.department}</span>
         </div>}



         <h6 className='text-[12px] font-[400] mb-1 mt-1'>Designation </h6>
         <Select
           value={data.designation_id} 
           error={error.designation_id}
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'designation_id')} 
           options={designationArr} 
           style={{fontSize:'12px'}}
           className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'

           />
         {error.designation_id !== '' && error.designation_id !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.designation_id}</span>
         </div>}

      

        <TextInput 
         label={'Official Email'}  
         variant="standard"
         name="official_email"
         type="text"
         error={error.official_email}
         value={data.official_email}
         handlechange={handlechange}
         placeholder="Enter your official email"
         />


        <TextInput 
         label={'Official Phone'}  
         variant="standard"
         name="official_phone"
         type="text"
         error={error.official_phone}
         value={data.official_phone}
         handlechange={handlechange}
         placeholder="Enter your official phone"
         />


        

          <div  className='flex mt-2 items-center'>
          <div className='flex items-center mt-0 mr-2'>
          {data?.is_verified ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,is_verified:!data.is_verified})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,is_verified:!data.is_verified})}  /> }
          {/* <input type={'checkbox'} checked={data?.is_verified} onChange={()=>setdata({...data,is_verified:!data.is_verified})} />  */}
          <h6 className="text-[10px] font-[600] ml-1">Is Verified</h6> 
          </div>  
          <div className='flex items-center mt-0 mr-2'>
          {data?.is_active ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,is_active:!data.is_active})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,is_active:!data.is_active})}  /> }
          {/* <input type={'checkbox'} checked={data?.is_active} onChange={()=>setdata({...data,is_active:!data.is_active})} />  */}
          <h6 className="text-[10px] font-[600] ml-1">Is Active</h6> 
          </div>  


         
          </div>   

          <div className='flex items-center'>
        
          <div className='flex items-center mt-0 mr-2'>
          {data?.app_access ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,app_access:!data.app_access})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,app_access:!data.app_access})}  /> }
          {/* <input type={'checkbox'} checked={data?.app_access} onChange={()=>setdata({...data,app_access:!data.app_access})} />  */}
          <h6 className="text-[10px] font-[600] ml-1">App Access</h6> 
          </div>
          </div>



         <div className=' flex mt-4 pb-5'>
            <ButtonOutlinedAutoWidth btnName={'Back'} onClick={()=>{navigate(-1)}} />
            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName={'Save'} onClick={submitdata} />
            </div>
         </div>
        </div>
        {(state !== null && state !== undefined) &&
        <div className='ml-5 mt-[9%] w-64'>
        <h6 className='text-[11px] bg-slate-100 p-2 font-[500]'>Use the below form to create or roles of an onboard employee</h6>
        <h6 className='text-[12px] font-[600] mb-1 mt-1'>Roles </h6>
         <Select
           value={data.roles} 
           error={error.roles}
           bordered={false}
           placeholder="" 
           mode="multiple"
           onChange={(e)=>handleSelect(e,'roles')} 
           options={rolesArr} 
           className='w-full border text-[12px] outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'

           />
         {error.roles !== '' && error.roles !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.roles}</span>
         </div>}

         <div className='flex w-full align-end items-end  mt-2'>
            <ButtonFilledAutoWidth btnName={'Save'} onClick={updateRoles} />
         </div>
          
        </div>}
         </div>
         </div>
         </div>




    </div>
  )
}

export default Onboard