import {  get, publicPost, put } from "../helpers/apihelpers";

export const CreateOnboardService=async(data)=>{
    try {
        const result = await publicPost(`api/user/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateOnboardService=async(data,id)=>{
    try {
        const result = await put(`api/user/update_appplication/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetOnboardDetailService=async(id)=>{
    try {
        const result = await get(`api/user/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetOnboardListService=async(page)=>{
    try {
        const result = await get(`api/user/get/${page}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateRoleService = async (data) => {
    try {
        const result = await put(`api/user/assign-user-roles`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}