import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import { combineReducers } from '@reduxjs/toolkit'
import AuthReducer from './reducers/AuthReducer'
import thunk from 'redux-thunk'

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
      Auth:AuthReducer     
})

const persistedReducer = persistReducer(persistConfig,rootReducer)

export const store =  configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware:[thunk]
});

export const persistor = persistStore(store);