import React from 'react'
import ProfileMenu from './ProfileMenu'
import { ButtonOutlinedAutoWidthSmall } from '../../components/button'
import { useSelector } from 'react-redux'

function UserNotification() {

  const user = useSelector(state=>state.Auth)


  return (
    <div className='max-h-screen overflow-y-scroll overflow-x-hidden px-2 mx-0 box-border '>


    <div className='block sm:flex'>
    <ProfileMenu />

    <div className='md:ml-6 w-[92%] md:w-full ml-4 md:mt-4 md:pt-5'>
    <h6 className='border-b mt-1 pb-2 mb-4 font-[700] text-[17px] border-[#d0d7de]'>Notifications</h6>

    <div className='border rounded-md max-w-[70%] md:max-w-[60%] border-[#d0d7de] p-3'>
    <h6 className='text-[13px] font-[600]'>
      Default notifications email
    </h6>

    <h6 className='text-[13px]  mt-2 leading-normal'>
     Choose where you'd like emails to be sent. You cant add more email addresses rather than your organization email. Use custom routes to specify different email addresses to be used for individual organizations.
    </h6>

    <div className='mt-2'>
    <ButtonOutlinedAutoWidthSmall btnName={user?.official_email} />
    </div>
    </div>
    
    </div>
    </div>
    </div>
  )
}

export default UserNotification