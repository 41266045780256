import React, { useEffect, useState } from 'react'
import SettingsMenu from './SettingsMenu'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { DatePicker,Modal,Select } from 'antd';
import {AiOutlineFilter,AiOutlinePlus,AiOutlineDelete,AiOutlineEdit} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, Tooltip } from '@mui/material'
import {BsArrowRepeat} from 'react-icons/bs';
import { DeleteInOutService, DownloadAttendanceAdminService, GetFilterCheckInOutService, UpdateAttendanceAdminService } from '../../services/AttendanceServices';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { SearchUser } from '../../services/AuthServices';
import toast from 'react-hot-toast';
import { TextInput } from '../../components/input';
import fileDownload from "js-file-download";
import axios from 'axios';

function UserAttendances() {

  const user = useSelector(state=>state.Auth)
  // console.log("user",user)
  const navigate = useNavigate()
  const [data,setdata]  = useState([])
  const [users,setusers] = useState([])
  const [search,setsearch] = useState({user:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})
  const [page,setpage] = useState(1)
  const [selectedData,setselectedData] = useState({})
  const [error,seterror] = useState({})
  const [modal,setmodal] = useState(false)
  const [edit_modal,setedit_modal] = useState(false)
  
  useEffect(()=>{
    if(search?.activate){
      getData(page)
    }else{
      getData(page)
    }

  },[page])

  async function handlechange(e){
    setselectedData({...selectedData,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function getData(){
    const response = await GetFilterCheckInOutService({...search,user:search?.user?.value === undefined ? '' : search?.user?.value,page:page})
    setdata(response?.data)
  }

  async function handleSelect(e,e1){
    if(e1 === 'user'){
      setsearch({...search,user:users?.find((v)=>v?.value === e)})
    }
  }

  async function searchUser(v){
    const response = await SearchUser(v)
    let arr = []
    response?.data?.datas?.forEach((d)=>{
      arr?.push({label:d?.name,value:d?._id})
    })
    setusers(arr)
  }

  async function applyfilterfunction(){
    setsearch({...search,activate:true})
    const response = await GetFilterCheckInOutService({...search,user:search?.user?.value === undefined ? '' : search?.user?.value,page:1})
    setdata(response?.data)
  }  

  async function resetfunc(){
    setsearch({user:'',from_date:'',to_date:'',activate:false})
    setpage(1)
  }

  // console.log("search",search)

  async function deleteData(){
      const response = await DeleteInOutService(selectedData?._id)
      if(response?.status == 200){
        toast.success("Deleted Successfully")
        setmodal(false)
        getData()
      }
  }

  async function updateData(){
    if(!selectedData.log_in){
      seterror({...error,log_in:'This Field is required*'})
    }else if(!selectedData.log_out){
      seterror({...error,log_out:'This Field is required*'})
    }else{
      const response = await UpdateAttendanceAdminService(selectedData?._id,selectedData)
      if(response?.status == 200){
        toast.success("Updated Successfully")
        setedit_modal(false)
        getData()
      }
    }
  }

  async function downloadData(){
    const response = await DownloadAttendanceAdminService({...search,user:search?.user?.value === undefined ? '' : search?.user?.value,page:1})
    let path = response.data.path.replace('public/','')
    handleDownload(`${process.env.REACT_APP_BACKEND_URL}${path}`,path.split('/')[path.split('/')?.length - 1])
    // console.log("response",response)
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            toast.success("Excel Downloaded") 
        })
}

console.log("search",search)
  return (
    <div className='min-h-screen max-h-screen overflow-hidden'>
      <Modal open={edit_modal} width={300} closable={false} footer={null}>
        <h4 className='text-[14px] font-[700]'>Update Data</h4>
        <h1 className='text-[12px] leading-normal'>Are You sure want to update the selected attendance data for user <span>{selectedData?.user_id?.name}</span> on date <span>{moment(selectedData?.createdAt)?.format('LL')}</span></h1>

        <TextInput 
          label = "Log In"
          name = "log_in"
          value={selectedData?.log_in}
          error={error?.log_in}
          handlechange={handlechange}
        />

        <TextInput 
          label = "Log Out"
          name = "log_out"
          value={selectedData?.log_out}
          error={error?.log_out}
          handlechange={handlechange}
        />

        <div className='flex mt-2 border-t pt-2'>
          <ButtonOutlinedAutoWidth btnName="Cancel"  onClick={()=>setedit_modal(false)}/> 
          <h6 className='w-[10px]'></h6>
          <ButtonFilledAutoWidth btnName="Sure"  onClick={()=>updateData()} /> 
        </div>
      </Modal>
      <Modal open={modal} width={300} closable={false} footer={null}>
        <h4 className='text-[14px] font-[700]'>Delete Data</h4>
        <h1 className='text-[12px] leading-normal'>Are You sure want to deleted the selected attendance data for user <span>{selectedData?.user_id?.name}</span> on date <span>{moment(selectedData?.createdAt)?.format('LL')}</span></h1>
        
        <div className='flex mt-2 border-t pt-2'>
          <ButtonOutlinedAutoWidth btnName="Cancel"  onClick={()=>setmodal(false)}/> 
          <h6 className='w-[10px]'></h6>
          <ButtonFilledAutoWidth btnName="Sure"  onClick={()=>deleteData()} /> 
        </div>
      </Modal>
        <div className='flex'>
        <SettingsMenu />
        <div className='mx-3 mt-5 w-full'>
            <div>
            <div className='block   md:flex items-center justify-between border-b pb-2'>
            <div className='flex justify-between items-center'>
              <h6 className='font-[500] text-[13px] '>Total Attendance Data Found  ({data?.pagination?.total})</h6>
              <div className='block md:hidden  items-center'>
              <div className='flex items-center text-[12px] -mr-2 md:mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>
              </div>
              </div>

              <div className='flex items-center md:justify-between mt-2 md:mt-0'>
              <div className='hidden md:block  items-center'>
              <div className='flex items-center text-[12px] -mr-2 md:mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>
              </div>
              <DatePicker size='small' style={{fontSize:'11px',minWidth:'90px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',v1);setsearch({...search,from_date:v,from_date1:v1})}} /> 

              <DatePicker size='small' style={{fontSize:'11px',minWidth:'90px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',v1);setsearch({...search,to_date:v,to_date1:v1})}} /> 

              <div className='mr-2 w-[120px]'>
              <Select
                showSearch 
                filterOption={false} 
                value={search.user} 
                bordered={false}
                placeholder="" 
                onChange={(e)=>handleSelect(e,'user')} 
                options={users}
                onSearch={searchUser}
                className='w-full border border-slate-300 rounded-[6px]  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />
              {/* <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className=' text-[12px] border py-[6.5px] focus:ring-0 focus:outline-0 w-[70px] md:w-28 px-2 rounded-md border-slate-300' /> */}
              </div>      

              <div className='hidden md:block'>
              <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
             
              </div>
              <AiOutlineFilter onClick={()=>applyfilterfunction(1)} size={26} className='border-slate-300 bg-[#f6f8fa] rounded-[3px] px-1 p-1 text-[#000] block md:hidden' />
              <div className='ml-2 hidden md:block'>
              <ButtonFilledAutoWidth btnName="Download Excel" onClick={()=>downloadData()}/> 
              </div>
              <AiOutlinePlus onClick={()=>navigate('/allocate/site_list/create')} size={26}  className='bg-amber-400 rounded-[3px] px-1 ml-2 p-1 text-black block md:hidden' />
              </div>
           </div>

           {data?.datas?.length === 0 &&
              <div className='grid place-items-center mt-20  items-center justify-center'>
              <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
              <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
              <h6 className='font-[400] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>
           }


        {data?.datas?.length > 0 &&
        <div className='max-h-[85vh] w-auto mt-4 border-t   border-l border-r overflow-y-scroll'>
        <div className='md:sticky top-0 z-50 flex  w-full bg-white py-[-5px]  border-slate-200 relative'>
        {/* <p className='w-[13px] h-[13px] rounded-[2px] border p-[1.6px] flex items-center justify-center' > </p>  */}
            <h6  className='top-0 bg-white z-50 text-[10px] w-[50px]  border-b  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'> SL NO </h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[120px]  md:w-[140px]  px-2 py-1 font-[600] text-slate-600 border-r'>User Details</h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[100px]  md:w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created On</h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[120px]  md:w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Log In Time</h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[120px]  md:w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Log out Time</h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[120px]  md:w-[250px]  px-2 py-1 font-[600] text-slate-600 border-r'>Log In Location</h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[120px]  md:w-[250px]  px-2 py-1 font-[600] text-slate-600 border-r'>Log Out Location </h6>
            <h6 className='md:sticky md:top-0 z-50  text-[12px] border-b min-w-[100px]  md:w-[120px]  px-2 py-1 font-[600] text-slate-600 '>Actions</h6>
        </div>
      
        {data?.datas?.map((d,i)=>(
        <div  key={d?._id} className={`flex z-50 w-[100%] border-slate-200 z-0`}  >
          {/* <p className='w-[13px] h-[13px] rounded-[2px] border p-[1.6px] flex items-center justify-center' ></p> */}
            <h6 className='top-0 z-30 text-[12px] text-[10px] w-[50px]  py-1 border-b font-[400] text-slate-600 cursor-pointer border-r pl-3'>{page === 1 ? (i+1) : ((i+1) * page * data?.pagination?.limit)}</h6>

            <h6 className='text-[11px] font-[400] min-w-[120px]  md:w-[140px] border-b px-2 py-1 truncate border-r  border-slate-200'>{d?.user_id?.name}</h6>
            <h6 className='text-[11px] font-[400]  min-w-[120px]  md:w-[100px] border-b px-2 py-1 truncate border-r  border-slate-200'>{moment(d?.createdAt).format('ll')}</h6>
            <h6 className='text-[11px] font-[400]  min-w-[120px]  md:w-[100px] border-b px-2 py-1 truncate border-r  border-slate-200'>{d?.log_in !== undefined ? d?.log_in : 'Not Added'}</h6>
            <h6 className='text-[11px] font-[400]  min-w-[120px]  md:w-[100px] border-b px-2 py-1 truncate border-r  border-slate-200'>{d?.log_out !== undefined ? d?.log_out : 'Not Added'}</h6>
            <h6 className='text-[11px] font-[400]  min-w-[120px]  md:w-[250px] border-b px-2 py-1 truncate border-r  border-slate-200 '>{d?.log_in_location}</h6>
            <h6 className='text-[11px] font-[400]  min-w-[120px]  md:w-[250px] border-b px-2 py-1 truncate border-r  border-slate-200 '>{d?.log_out_location}</h6>
            <h6  className='text-[11px] min-w-[100px]  md:w-[120px] border-b px-2 py-1 flex'>
             

              <Tooltip title="Edit">
              <span><AiOutlineEdit size={12} className="z-10 ml-3" onClick={()=>{setselectedData(d);setedit_modal(true)}}/></span>
              </Tooltip>

              {/* <Tooltip title="Preview">
              <span><VscOpenPreview size={13} className="z-10 ml-2" onClick={()=>{setselected_lead(d);setpreview(true)}}/></span>
              </Tooltip> */}
              {user?.roles?.includes('hod') &&
              <Tooltip title="Delete">
              <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{setselectedData(d);setmodal(true)}}/></span>
              </Tooltip>}
              

            
            </h6>
        </div>))}
        </div>}
           
            </div>
        </div>
        </div>
    </div>
  )
}

export default UserAttendances