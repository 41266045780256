import React from 'react'
import SettingsMenu from './SettingsMenu'
import { ButtonOutlinedAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast';
import { DeleteAllLandAllocateService } from '../../services/LandAllocateServices';

function DeleteData() {


  async function deleteAllData(){
    const response = await DeleteAllLandAllocateService()
    if(response?.status == 200){
      toast.success(response?.data?.data)
    }
  }

  return (
    <div className='min-h-screen max-h-screen overflow-hidden'>
        <div className='flex'>
        <SettingsMenu />
        <div className='mx-3 mt-5 w-full'>
            <div>
            <h6 className='font-[800] text-[16px] border-b  pb-2'>Delete Report </h6>    
            <h6 className='text-[11.5px] leading-tight w-72 mt-2'>Hello use the below form to delete all the site allocated data.</h6>

            <div className='mt-4'> 
            <ButtonOutlinedAutoWidth btnName="Download Report" onClick={deleteAllData} />
            </div>  
            </div>
        </div>
        </div>
    </div>
  )
}

export default DeleteData