import React,{useEffect, useState} from 'react'



import { TextInput } from '../../components/input'
import { LogInService } from '../../services/AuthServices';
import { useDispatch } from 'react-redux';
import { LogInAction, LogOutAction } from '../../redux/actions/authAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {AiFillEye,AiFillEyeInvisible} from 'react-icons/ai';

function Login() {

    const [data,setdata] = useState({user_id:'',password:''});
    const [error,seterror] = useState({user_id:'',password:''});

    const [visible,setvisible] = useState(false)

    
    const dispatch = useDispatch();
    const navigate = useNavigate();



    useEffect(()=>{  
        dispatch(LogOutAction())
    },[])


    function handlechange(e){
        setdata({...data,[e.target.name] : e.target.value})
        seterror({...error,[e.target.name]:''})
    }

    function onKeyDownHandler(e){
       
        if (e.keyCode === 13) {
            submitform();
        }
    }

    async function submitform(){

        if(!data.user_id){
            toast.error('FCPL ID is required')
            seterror({...error,user_id:'FCPL ID is required'})
        }else if(!data.user_id.toLowerCase().includes('fcpl_')){
            toast.error('Invalid FCPL ID')
            seterror({...error,user_id:'Invalid FCPL ID'})
        }
        else if(!data.password){
            toast.error('Password field is required')
            seterror({...error,password:'Password field is required'})
        }else{
            const response = await LogInService(data)
            if(response.status === 200){
      
                dispatch(LogInAction(response.data))
                if(response?.data?.roles?.includes('bd_user') || response?.data?.roles?.includes('manager') || response?.data?.roles?.includes('hod')){
                    navigate('/dashboard')
                }else if(response?.data?.roles?.includes('finance_head') || response?.data?.roles?.includes('finance_manager')){
                    navigate('/finance')
                }
            }else if(response.status === 401){
                toast.error(response.data.errors)
                seterror({...error,password:response.data.errors})
            }
        }
    }

  return (

   

    <div className='flex overflow-y-hidden items-center h-screen min-h-screen max-h-screen justify-center md:justify-between'>

     <div className='hidden md:block min-w-[70vw] w-[70vw] max-w-[70vw]  bg-amber-400'>
        <img src="https://fidecrmfiles.s3.amazonaws.com/crmposter.avif" className='w-full h-full object-cover opacity-20' />
    </div>   


    <div className='min-w-[70vw] w-[70vw] max-w-[70vw]  md:min-w-[30vw] md:w-[30vw] md:max-w-[30vw] flex justify-center items-center' >

         <div className=''>
            <div className='p-1 w-72'  >  

            <h6 className='text-[18px] font-[800]'>Sign In</h6>      
            <h6 className={`text-[10px] font-[400] leading-normal mb-4 my-2`}>Add your employee Id and password to access all the features provided to you.</h6>
            
            <form
             onKeyDown={onKeyDownHandler}
             onSubmit={submitform}
            > 
            <TextInput 
                label={'USER ID or FCPL ID'}  
                variant="standard"
                name="user_id"
                type="text"
                value={data.user_id}
                handlechange={handlechange}
                placeholder="Enter your FCPL_ID"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


            <div className='relative'>
            <TextInput 
                label={'Password'}  
                variant="standard"
                name="password"
                type={visible ? "text" : "password"}
                // error={error.password}
                value={data.password}
                handlechange={handlechange}
                placeholder="Enter your password"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  
               {visible ?  <AiFillEye className='absolute right-1 top-[24px] cursor-pointer text-slate-200 ' onClick={()=>setvisible(!visible)} /> : <AiFillEyeInvisible className='absolute right-1 top-[24px] cursor-pointer text-slate-200 ' onClick={()=>setvisible(!visible)} />}
            </div>



                <h6 onClick={submitform} className="bg-amber-400 cursor-pointer text-center mt-4  font-[500] py-[8px] px-4 mt-0  w-full rounded-md border-slate-600  text-[14px] hover:border-slate-600">SIGN IN</h6>
            </form>

            <h6 className='text-[10px] mt-4 text-800 leading-normal'>By continuing, you agree to Fidelitus<span className='text-black font-bold text-[#0a69da]'> Conditions of Use</span>  and <span className='text-black font-bold text-[#0a69da]'> Privacy Notice </span> .</h6>


            <h6 onClick={()=>navigate('/forgot_password')}  className="w-full  py-2 px-4 mt-5 rounded text-center  text-[12px] cursor-pointer">Forgot Password</h6>

            </div>
        </div>  
    </div> 

  
    </div>    


      
  )
}

export default Login