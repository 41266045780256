import React, { useState } from 'react'
import SettingsMenu from './SettingsMenu'
import { ButtonOutlinedAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast';
import { DeleteAllLandAllocateService, UploadExcelDataService } from '../../services/LandAllocateServices';
import Uploader from '../../components/Uploader';

function UploadExcelData() {
 
  const [file,setfile] = useState(null)  
  const [error,seterror] = useState('')  

  async function uploadExcel(){
    const fd = new FormData()
    fd.append('file',file)
    const response = await UploadExcelDataService(fd)
    if(response?.status == 200){
      toast.success(response?.data?.data)
    }
  }

  return (
    <div className='min-h-screen max-h-screen overflow-hidden'>
        <div className='flex'>
        <SettingsMenu />
        <div className='mx-3 mt-5 w-full'>
            <div className='w-64'>
            <h6 className='font-[800] text-[16px] border-b  pb-2'>Upload Excel </h6>    
            <h6 className='text-[11.5px] leading-tight w-72 mt-2'>Please use the below form to upload the excel for mysore data to upload.</h6>
            
            <Uploader error={error} name="file" required={true} label={'Excel File'} image={file}  setimagefunc={(e)=>{setfile(e);seterror('')}}  removeimageuploadfunc = {()=>{setfile(null);seterror('')}} />


            <div className='mt-4'> 
            <ButtonOutlinedAutoWidth btnName="Upload Excel" onClick={uploadExcel} />
            </div>  
            </div>
        </div>
        </div>
    </div>
  )
}

export default UploadExcelData