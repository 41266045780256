import React, { useEffect, useState } from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
// import {BiCheckbox,BiCheckboxSquare,BiErrorCircle} from 'react-icons/bi'

import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import CallsMenu from './CallsMenu'
import { CreateUserCallService, GetUserCallDetailService, GetUserCallService, UpdateUserCallService } from '../../services/UserCallsServices'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'

function CallsListCE() {


  const {state} = useLocation()
  const navigator = useNavigate()

  console.log("state",state)

  useEffect(()=>{
       if(state !== null && state !== undefined && state !== null){
          getData(state?._id)
       }
  },[])


  async function getData(v){
      const response = await GetUserCallDetailService(v,1)
      let d = response?.data?.datas[0]
      setdata({...d})
      // console.log("response",response)
  }


  const [step,setstep] = useState(1)

  const [data,setdata] = useState(
    {
      name:'',
      comments:'',
      phone:'',
      type:'Outgoing'
    }
  )
  const [error,seterror] = useState(
    {
      name:'',
      comments:'',
      phone:'',
      type:''
      
    }
  )



  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
  }

  function goBack(){
    navigator(-1)
  }

  async function submitData(){
      if(!data?.name){
        seterror({...error,name:'This Field is required*'})
      }else if(!data?.phone || data?.phone?.length !== 10){
        seterror({...error,mobile:'Enter a valid 10 digit mobile no*'})
      }else{
          const sendData = {
            name:data?.name,
            phone:data?.phone,
            comments:data?.comments,
            type:data?.type
          }
          
          if(state !== null && state !== undefined && state !== ''){
            const response = await UpdateUserCallService(sendData,state?._id)
            if(response.status === 200){
              toast.success(response?.data?.datas)
              resetForm()
              setTimeout(() => {
                  navigator(-1)
              }, 1500);

            }
          }else{
            const response = await CreateUserCallService(sendData)
            if(response.status === 201){
              toast.success(response?.data?.datas)
              resetForm()
            }
          }
      }
    }


  function resetForm(){
     setdata( {
      name:'',
      comments:'',
      phone:'',
      type:''
    })

    seterror( {
      name:'',
      comments:'',
      phone:'',
      type:''
    })
  }

  // console.log("data",data)

  return (
    <div className='md:pr-5 min-h-screen max-h-screen h-screen overflow-y-scroll'>
      
      <div className='block md:flex w-full'>
       <CallsMenu />   
       
       <div className='p-4 md:w-72 mt-2'>
            <div className='border-b   pb-4 mb-4'>
                <h6 className='font-[600] text-[15px] mb-1'>Cread / Update the Land Allocated</h6> 
                <h6 className='text-[11px] mt-2 leading-tight font-[400] p-2 bg-slate-100 '>Use the below form to create or edit the land allocated for your customer reference to get the data added  by you all the time.</h6>

                <div >
                <div className='w-[100%]  md:mr-2'>
                   <div>
                   <TextInput 
                      mandatory={true}
                      label={'Name'}  
                      variant="standard"
                      name="name"
                      type="text"
                      value={data.name}
                      error={error.name}
                      handlechange={handlechange}
                      placeholder="Enter  name"
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div>  

                   <div>
                   <TextInput 
                      mandatory={true}
                      label={'Mobile'}  
                      variant="standard"
                      name="phone"
                      type="text"
                      value={data.phone}
                      error={error.phone}
                      handlechange={handlechange}
                      placeholder="Enter contact name"
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div>  


                   <h6 className='text-[11px] font-[400] mb-1 mt-2.5' >{'Call Type'}</h6>
                        <div className='flex mb-2.5'>
                        <div className='flex items-center mt-0 mr-2'>
                          {data?.type === 'Incoming'  ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,type:'Outgoing'})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,type:'Incoming'})}  /> }
                          <h6 className="text-[12px] font-[400] ml-2">Incoming</h6> 
                        </div> 

                        <div className='flex items-center mt-0 mr-2'>
                          {data?.type === 'Outgoing' ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,type:'Incoming'})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,type:'Outgoing'})}  /> }
                          <h6 className="text-[12px] font-[400] ml-2">Outgoing</h6> 
                        </div> 
                </div>

                   <div>
                   <TextAreaInput1 
                      mandatory={false}
                      label={'Summary'}  
                      variant="standard"
                      name="comments"
                      type="text"
                      value={data.comments}
                      error={error.comments}
                      handlechange={handlechange}
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>
                   </div>  

                

                  
                   
                </div> 

              
                </div>

              


               
            </div> 
            <div className='mt-2 flex items-center justify-end'>
                  <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={goBack} />
                  <h6 className='w-2'></h6>
                  <ButtonFilledAutoWidth btnName={'Save'} onClick={submitData}  />
                </div>
       </div>


      </div>
    </div>
  )
}

export default CallsListCE