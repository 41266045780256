import Login from "../../screens/auth/Login";
import DesignationCE from "../../screens/options/designation/DesignationCE";
import DepartmentCE from "../../screens/options/department/DepartmentCE";
import DepartmentList from "../../screens/options/department/DepartmentList";
import DesignationList from "../../screens/options/designation/DesignationList";
import RoleList from "../../screens/options/roles/RoleList";
import RoleCE from "../../screens/options/roles/RoleCE";
import Settings from "../../screens/staticscreens/Settings";
import Support from "../../screens/staticscreens/Support";
import Profile from "../../screens/profile/Profile";

// import OnboardList from "../../screens/onboard/OnboardList";
// import Onboard from "../../screens/onboard/Onboard";
import PrivacyPolicy from "../../screens/staticscreens/PrivacyPolicy";
import Mainprofile from "../../screens/profile/Mainprofile";
import UpdatePassword from "../../screens/auth/UpdatePassword";
import Account from "../../screens/profile/Account";
import ThemeUser from "../../screens/profile/ThemeUser";
import UserNotification from "../../screens/profile/UserNotification";
import UserOrganization from "../../screens/profile/UserOrganization";
import Accessability from "../../screens/profile/Accessablity";


import OnboardList from "../../screens/onboard/OnboardList";
import Onboard from "../../screens/onboard/Onboard";
import DashboardMenu from "../../screens/dashboard/DashboardMenu";
import Dashboard from "../../screens/dashboard/Dashboard";
import AllocateMenu from "../../screens/allocate/AllocateMenu";
import AllocationSiteCE from "../../screens/allocate/AllocationSiteCE";
import AllocateSiteList from "../../screens/allocate/AllocateSiteList";
import AllocateDetail from "../../screens/allocate/AllocateDetail";
import CallsMenu from "../../screens/calls/CallsMenu";
import CallsList from "../../screens/calls/CallsList";
import CallsListCE from "../../screens/calls/CallsListCE";
import DeleteData from "../../screens/staticscreens/DeleteData";
import UploadExcelData from "../../screens/staticscreens/UploadExcelData";
import UserAttendances from "../../screens/staticscreens/UserAttendances";

// import DashboardMenu from "../../screens/dashboard/DashboardMenu";

export const routes = [
    {
        path:"/",
        component:<Login/>,
        private:false,
        roles:[]
    },
    {
        path:"/settings",
        component:<Settings/>,
        private:true,
        roles:['controller','admin']
    },
   
    {
        path:"/settings/department",
        component:<DepartmentList/>,
        private:true,
        roles:['controller','admin']
    },
   
    {
        path:"/dashboard",
        component:<DashboardMenu/>,
        private:true,
        roles:[]
    },
    {
        path:"/dashboard/my_dashboard",
        component:<Dashboard/>,
        private:true,
        roles:[]
    },
    {
        path:"/allocate",
        component:<AllocateMenu/>,
        private:true,
        roles:[]
    },
    {
        path:"/allocate/site_list",
        component:<AllocateSiteList/>,
        private:true,
        roles:[]
    },
    {
        path:"/allocate/site_list/create",
        component:<AllocationSiteCE/>,
        private:true,
        roles:[]
    },
    {
        path:"/allocate/site_list/edit",
        component:<AllocationSiteCE/>,
        private:true,
        roles:[]
    },
    {
        path:"/allocate/site_list/detail",
        component:<AllocateDetail/>,
        private:true,
        roles:[]
    },
    {
        path:"/calls",
        component:<CallsMenu/>,
        private:true,
        roles:[]
    },
    {
        path:"/calls/list",
        component:<CallsList/>,
        private:true,
        roles:[]
    },
    {
        path:"/calls/list/:type",
        component:<CallsListCE/>,
        private:true,
        roles:[]
    },
   
   
    {
        path:"/settings/department/create",
        component:<DepartmentCE/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings/department/edit",
        component:<DepartmentCE/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings/designation",
        component:<DesignationList/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings/designation/create",
        component:<DesignationCE/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings/designation/edit",
        component:<DesignationCE/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings/roles",
        component:<RoleList/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings/roles/create",
        component:<RoleCE/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings/roles/edit",
        component:<RoleCE/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings",
        component:<Settings/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings/onboard_list",
        component:<OnboardList/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings/onboard_list/create",
        component:<Onboard/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings/onboard_list/edit",
        component:<Onboard/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings/mass_delete",
        component:<DeleteData/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings/upload_data",
        component:<UploadExcelData/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/settings/user_attendance",
        component:<UserAttendances/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/support",
        component:<Support/>,
        private:true,
        roles:[]
    },
    {
        path:"/profile",
        component:<Mainprofile/>,
        private:true,
        roles:[]
    },
    {
        path:"/privacy",
        component:<PrivacyPolicy/>,
        private:false,
        roles:[]

    },
    {
        path:"/profile",
        component:<Profile/>,
        private:true,
        roles:[]
    },
    {
        path:"/profile/basic",
        component:<Mainprofile/>,
        private:true,
        roles:[]
    },
    {
        path:"/profile/update_password",
        component:<UpdatePassword/>,
        private:true,
        roles:[]
    },
    {
        path:"/profile/account",
        component:<Account/>,
        private:true,
        roles:['controller','admin']
    },
    {
        path:"/profile/appearance",
        component:<ThemeUser/>,
        private:true,
        roles:[]
    },
    {
        path:"/profile/notifications",
        component:<UserNotification/>,
        private:true,
        roles:[]
    },
    {
        path:"/profile/organiztion",
        component:<UserOrganization/>,
        private:true,
        roles:[]
    },
    {
        path:"/profile/accessibility",
        component:<Accessability/>,
        private:true,
        roles:[]
    }
]