import React, { useEffect } from 'react';
import {useLocation,useNavigate } from 'react-router-dom';
import {MdOutlineSpaceDashboard,MdOutlineLeaderboard,MdPayment} from 'react-icons/md';
import { useSelector } from 'react-redux';
import {TbMoneybag} from 'react-icons/tb';
import {TfiDashboard} from 'react-icons/tfi';
import { VscGraphLine } from 'react-icons/vsc';



function DashboardMenu() {

  const {pathname} = useLocation();
  const path = pathname.split('/')[2]
  const user = useSelector(state=>state.Auth)

  const navigate = useNavigate();

  useEffect(()=>{
    if(path === undefined){
      navigate('/dashboard/my_dashboard')
    }
  },[])


  const menu = [
    {name:'My Dashboard',icon:MdOutlineSpaceDashboard,path:'/dashboard/my_dashboard',id:1,color:'',roles:['bd_user','manager','hod','admin']},
  ]  
  
  

  return (
    <div className='hidden md:block mr-0 min-h-screen max-h-sceen border-r  min-w-44 max-w-44 w-44 px-2' >

        <div className='mb-4 mt-5'>
        <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Our Dashboard</h6>

            {menu.map((m)=>(
               <React.Fragment key={m?.path}>
                {m?.roles?.filter((f)=> user?.roles?.includes(f))?.length > 0 &&
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                  <span className='text-[#000] font-[400] ml-2 text-[12px]'>{m.name}</span>
              </div>} 
              </React.Fragment> 
            ))}
        </div>

    </div>
  )
}

export default DashboardMenu