import { deleteRequest, get, postfd, put } from "../helpers/apihelpers";

export const CreateLandAllocateService=async(data)=>{
    try {
        const result = await postfd(`api/land_allocate/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateLandAllocateService=async(data,id)=>{
    try {
        const result = await postfd(`api/land_allocate/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetLandAllocateService=async(page)=>{
    try {
        const result = await get(`api/land_allocate/get?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetLandAllocateServiceDetail=async(id)=>{
    try {
        const result = await get(`api/land_allocate/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteLandAllocateService=async(id)=>{
    try {
        const result = await deleteRequest(`api/land_allocate/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAllLandAllocateService=async()=>{
    try {
        const result = await deleteRequest(`api/land_allocate/delete_all`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadExcelDataService=async(data)=>{
    try {
        const result = await postfd(`api/land_allocate/upload_excel`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterLandAllocateService=async({search_text,from_date,to_date,page})=>{
    try {
        const result = await get(`api/land_allocate/filter?search=${search_text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}